import { render, staticRenderFns } from "./CommonInfo.vue?vue&type=template&id=1d1883d9&scoped=true&"
import script from "./CommonInfo.vue?vue&type=script&lang=javascript&"
export * from "./CommonInfo.vue?vue&type=script&lang=javascript&"
import style0 from "./CommonInfo.vue?vue&type=style&index=0&id=1d1883d9&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d1883d9",
  null
  
)

export default component.exports