<!--
 * @Author: filename
 * @Description: 个人信息表
-->
<template>
    <div v-loading="isLoading" style="min-height: 350px;">
        <!-- 主申请人信息 -->
        <div class="card-container">
            <div class="card-heard">主申请人信息</div>
            <div v-loading="isLoading" class="form-box">
                <el-form
                    v-if="detailsInfo.information"
                    label-width="auto"
                    label-position="left"
                >
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="主申姓名：">
                                {{ detailsInfo.information.username }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="性别：">
                                {{ detailsInfo.information.sex === 1 ? '男' : '女' }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="出生日期：">
                                {{ detailsInfo.information.birthday }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="档案号：">
                                {{ detailsInfo.information.fileNumber || '-' }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="港澳通行证号码：">
                                {{ detailsInfo.information.passNumber || '-' }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="护照号码：">
                                {{ detailsInfo.information.identityCard || '-' }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="国籍：">
                                {{ detailsInfo.information.nationality }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="婚姻状况：">
                                {{ detailsInfo.information.married | filterMarried }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="现居地地址：">
                                {{ filterAddress(detailsInfo.information.address) }}
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </div>
        <!-- 受养人信息 -->
        <el-form
            label-width="auto"
            label-position="left"
        >
            <!-- 配偶  -->
            <div v-if="detailsInfo.subsetList && detailsInfo.subsetList.filter(item => item.relation === '3').length > 0" class="card-container">
                <div class="card-heard">主申请人配偶</div>
                <template v-for="(item, idx) in detailsInfo.subsetList">
                    <div v-if="item.relation === '3'" :key="idx" class="form-box">
                        <el-row :gutter="20">
                            <el-col :span="24">
                                <el-form-item label="是否随行：">
                                    {{ item.follow === 1 ? '随行' : '不随行' }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="配偶姓名：">
                                    {{ item.subsetName }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="性别：">
                                    {{ item.sex === 1 ? '男' : '女' }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="出生日期：">
                                    {{ item.birthday }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="档案号：">
                                    {{ item.fileNumber || '-' }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="港澳通行证号码：">
                                    {{ item.passNumber || '-' }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="护照号码：">
                                    {{ item.identityCard || '-' }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="国籍：">
                                    {{ item.nationality }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="12"/>
                            <el-col v-if="item.nows === 1" :span="24">
                                <el-form-item label="现居地地址：">
                                    {{ filterAddress(detailsInfo.information.address) }}
                                </el-form-item>
                            </el-col>
                            <el-col v-if="item.nows === 0" :span="24">
                                <el-form-item label="现居地地址：">
                                    {{ filterAddress(item.address) }}
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </template>
            </div>

            <!-- 子女 -->
            <div v-if="detailsInfo.subsetList && detailsInfo.subsetList.filter(item => item.relation === '4').length > 0" class="card-container">
                <div class="card-heard">子女</div>
                <template v-for="(item, idx) in detailsInfo.subsetList">
                    <div v-if="item.relation === '4'" :key="idx" :class="['form-box card-child', idx > 1 ? 'card-line' : '' ]">
                        <el-row :gutter="20">
                            <el-col :span="24">
                                <el-form-item label="是否随行：">
                                    {{ item.follow === 1 ? '随行' : '不随行' }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="子女姓名：">
                                    {{ item.subsetName }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="性别：">
                                    {{ item.sex === 1 ? '男' : '女' }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="出生日期：">
                                    {{ item.birthday }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="档案号：">
                                    {{ item.fileNumber || '-' }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="港澳通行证号码：">
                                    {{ item.passNumber || '-' }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="护照号码：">
                                    {{ item.identityCard || '-' }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="国籍：">
                                    {{ item.nationality }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="12"/>
                            <el-col v-if="item.nows === 1" :span="24">
                                <el-form-item label="现居地地址：">
                                    {{ filterAddress(detailsInfo.information.address) }}
                                </el-form-item>
                            </el-col>
                            <el-col v-if="item.nows === 0" :span="24">
                                <el-form-item label="现居地地址：">
                                    {{ filterAddress(item.address) }}
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </template>
            </div>
        </el-form>
    </div>
</template>

<script lang="javascript">
import { getInformationDetail } from "@/api/workfow.js"
import { mapActions, mapState } from "vuex"
export default {
    name: "SmallGiftedInfo",
    filters: {
        filterMarried(val) {
            switch (val) {
                case '1':
                    return '已婚'
                case '2':
                    return '未婚'
                case '3':
                    return '离婚'
                case '4':
                    return '分居'
                case '5':
                    return '丧偶'
                default:
                    return '未知'
            }
        }
    },
    data() {
        return {
            isLoading: false,
            detailsInfo: {
                information: null,
                subsetList: null
            }
        }
    },
    created() {
        this.getDetail()
    },
    methods: {
        // 获取详情
        async getDetail() {
            try {
                this.isLoading = true
                const { code, data } = await getInformationDetail({ orderId: this.$store.state.user.orderId })
                if (code === 200) {
                    this.detailsInfo = data
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }
        },
        // 现居住地处理
        filterAddress(val) {
            const { area, foreign, details, country } = val
            if (country) {
                if (country.includes('中国')) {
                    return `${country}${area ? area[0] + area[1] : ''}${details}`
                } else {
                    return `${country}${foreign}`
                }
            }
            return '-'
        }
    },
};
</script>

<style lang="less" scoped>
.card-container {
    width: 960px;
    margin: 0 auto;
    margin-bottom: 20px;
    .card-heard {
        height: 35px;
        line-height: 35px;
        background: #409eff;
        padding-left: 16px;
        border-radius: 4px 4px 0px 0px;
        color: #fff;
    }
}

.operations-group {
    width: 960px;
    margin: 25px auto 50px;
    display: flex;
    justify-content: flex-end;
}

.form-box {
    padding: 20px;
}

.card-line {
    border-top: 1px solid #eee;
    padding-top: 35px;
}
.card-child {
    border-bottom: 1px solid #eee;
}
.card-child:last-child {
    border-bottom: 0 none;
}
</style>
