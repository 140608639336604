<!--
 * @Author: Akko
 * @Description: 个人信息表(专才)
-->
<template>
    <div v-loading="loading">
        <div class="yourself">主申请人</div>
        <!-- 主申请人 -->
        <div class="yourself-table">
            <el-col :span="12">
                <div class="vi-item">
                    <div class="table-title">主申姓名:</div>
                    <div class="title-cont">{{ first.username }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">曾用名:</div>
                    <div class="title-cont">{{ first.used_name }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">性别:</div>
                    <div class="title-cont">
                        {{ first.sex == 1 ? "男" : "女" }}
                    </div>
                </div>
                <div class="vi-item">
                    <div class="table-title">出生日期:</div>
                    <div class="title-cont">
                        {{ first.birthday | getDate }}
                    </div>
                </div>
                <div class="vi-item">
                    <div class="table-title">出生城市:</div>
                    <div class="title-cont">
                        {{
                            first.birth_place_aboard===0? first.area[0]+first.area[1]:
                            first.birth_place
                        }}
                    </div>
                </div>
                <div class="vi-item">
                    <div class="table-title">婚姻状况:</div>
                    <div class="title-cont">
                        {{ first.married | marryInfo }}
                    </div>
                </div>
                <div class="vi-item">
                    <div class="table-title">国籍:</div>
                    <div class="title-cont">{{ first.nationality }}</div>
                </div>
                <!-- <div class="vi-item">
                    <div class="table-title">家庭地址:</div>
                    <div class="title-cont">
                        {{ first.address }}
                    </div>
                </div> -->
            </el-col>
            <el-col :span="12">
                <div class="vi-item">
                    <div class="table-title">港澳通行证号码:</div>
                    <div class="title-cont">{{ first.pass_number }}{{ first.is_handled==='1'?'(暂未办理)':'' }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">身份证号码:</div>
                    <div class="title-cont">{{ first.identity_card }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">手机号:</div>
                    <div class="title-cont">{{ first.mobile }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">邮箱:</div>
                    <div class="title-cont">{{ first.email }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">现定居国家:</div>
                    <div class="title-cont">{{ first.live_country ? first.live_country : "中国" }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">居留时间:</div>
                    <div class="title-cont">{{ first.live_date }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">在港身份:</div>
                    <div class="title-cont">{{ first.hk_identity }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">目前居住地址:</div>
                    <div v-if="first.hasOwnProperty('address')" class="title-cont">
                        {{
                            !first.address.foreign
                                ? first.address.area.join('') +
                                    first.address.details
                                : first.address.foreign +
                                    first.address.details
                        }}
                    </div>
                </div>
            </el-col>
        </div>
        <!-- 家庭成员 -->
        <div class="family-member">
            <div class="title-bar">家庭成员</div>
            <div v-for="(Data, index1) in second" :key="index1" class="one-fam">
                <div class="one-member">
                    {{ Data.relation | relationFilter }}({{ Data.follow==1?'随行':'不随行' }})
                </div>
                <!-- <div v-if="Data.relation!=='3'" class="member-cont"> -->
                <div class="member-cont">
                    <el-col :span="12">
                        <div class="vi-item">
                            <div class="table-title">姓名:</div>
                            <div class="title-cont">{{ Data.subset_name }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">曾用名:</div>
                            <div class="title-cont">{{ Data.used_name }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">出生日期:</div>
                            <div class="title-cont">
                                {{ Data.birthday | getDate }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">出生城市:</div>
                            <div class="title-cont">
                                {{ Data.birth_place_aboard===0? Data.area[0]+Data.area[1]: Data.birth_place }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">国籍:</div>
                            <div class="title-cont">
                                {{ Data.nationality }}
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="vi-item">
                            <div class="table-title">港澳通行证号码:</div>
                            <div class="title-cont">{{ Data.pass_number }}{{ Data.is_handled==='1'?'(暂未办理)':'' }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">中国身份证号码:</div>
                            <div class="title-cont">{{ Data.identity_card }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">现定居国家:</div>
                            <div class="title-cont">{{ Data.live_country ? Data.live_country : "中国" }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">在港身份:</div>
                            <div class="title-cont">{{ Data.hk_identity }}</div>
                        </div>
                        <div v-if="Data.relation == 3 || Data.relation == 4" class="vi-item">
                            <div class="table-title">目前居住地址:</div>
                            <div v-if="Data.hasOwnProperty('address')" class="title-cont">
                                {{
                                    Data.address.area.length
                                        ? Data.address.area.join('')
                                        : Data.address.foreign
                                }}
                            </div>
                        </div>
                    </el-col>
                </div>
            </div>
        </div>

        <!-- 基本资格 -->
        <div class="quali-table">
            <div class="title-bar">基本资格</div>
            <div v-for="(Data, index1) in third" :key="index1">
                <div v-if="Data.relation==0" class="own">主申请人</div>
                <div v-if="Data.relation==3" class="own">配偶 - {{ Data.subset_name }}</div>
                <div v-if="Data.relation==4" class="own">子女 - {{ Data.subset_name }}</div>
                <div class="f-gui">
                    <div class="if-title">
                        1.是否拥有现时定居国家的永久居留身份？
                    </div>
                    <div class="is-yes">
                        {{ Data.country_identity.has == "Y" ? "是" : "否"
                        }}<span v-if="Data.country_identity.has == 'Y'">
                            -
                            {{
                                Data.country_identity.has == "Y"
                                    ? Data.country_identity.info
                                    : ""
                            }}</span>
                    </div>
                </div>
                <div class="f-gui">
                    <div class="if-title">
                        2.是否现正在香港？
                    </div>
                    <div class="is-yes">
                        {{ Data.child_in_hk.has == "Y" ? "是" : "否"
                        }}<span v-if="Data.child_in_hk.has == 'Y'">
                            -
                            {{
                                Data.child_in_hk.has == "Y"
                                    ? Data.child_in_hk.info
                                    : ""
                            }}</span>
                    </div>
                </div>
                <div class="f-gui">
                    <div class="if-title">
                        3.是否曾在香港或其他地方被拒入境／递解／遣送或要求离境？
                    </div>
                    <div class="is-yes">
                        {{ Data.child_departure.has == "Y" ? "是" : "否"
                        }}<span v-if="Data.child_departure.has == 'Y'">
                            -
                            {{
                                Data.child_departure.has == "Y"
                                    ? Data.child_departure.info
                                    : ""
                            }}</span>
                    </div>
                </div>
                <div class="f-gui">
                    <div class="if-title">
                        4.是否曾被拒绝签发香港或其他地方的签证／进入许可？
                    </div>
                    <div class="is-yes">
                        {{ Data.child_deny.has == "Y" ? "是" : "否"
                        }}<span v-if="Data.child_deny.has == 'Y'">
                            -
                            {{
                                Data.child_deny.has == "Y"
                                    ? Data.child_deny.info
                                    : ""
                            }}</span>
                    </div>
                </div>
                <div v-if="Data.relation==0" class="f-gui">
                    <div class="if-title">
                        5.是否办理过香港身份证？
                    </div>
                    <div class="is-yes">
                        {{ Data.has_hk_id.has == "Y" ? "是" : "否"
                        }}<span v-if="Data.has_hk_id.has == 'Y'">
                            -
                            {{
                                Data.has_hk_id.has == "Y"
                                    ? Data.has_hk_id.info
                                    : ""
                            }}</span>
                    </div>
                </div>
                <div v-if="Data.relation==3" class="f-gui">
                    <div class="if-title">
                        5.是否办理过香港身份证？
                    </div>
                    <div class="is-yes">
                        {{ Data.child_has_hk_id.has == "Y" ? "是" : "否"
                        }}<span v-if="Data.child_has_hk_id.has == 'Y'">
                            -
                            {{
                                Data.child_has_hk_id.has == "Y"
                                    ? Data.child_has_hk_id.info
                                    : ""
                            }}</span>
                    </div>
                </div>
                <div v-if="Data.relation==4" class="f-gui">
                    <div class="if-title">
                        5.是否为前次婚姻的子女？
                    </div>
                    <div class="is-yes">
                        {{ Data.child_stepchild.has == "Y" ? "是" : "否"
                        }}<span v-if="Data.child_stepchild.has == 'Y'">
                            -
                            {{
                                Data.child_stepchild.has == "Y"
                                    ? Data.child_stepchild.info
                                    : ""
                            }}</span>
                    </div>
                </div>
                <div v-if="Data.relation==4" class="f-gui">
                    <div class="if-title">
                        6.是否办理过香港身份证？
                    </div>
                    <div class="is-yes">
                        {{ Data.child_has_hk_id.has == "Y" ? "是" : "否"
                        }}<span v-if="Data.child_has_hk_id.has == 'Y'">
                            -
                            {{
                                Data.child_has_hk_id.has == "Y"
                                    ? Data.child_has_hk_id.info
                                    : ""
                            }}</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- 主申学历及资格证书 -->
        <div class="education">
            <div class="title-bar">
                主申学历及资格证书
            </div>
            <!-- 学历 -->
            <div v-for="(item2, index2) in fourth.education" :key="'key2' + index2">
                <div class="own">学历{{ index2 + 1 }}</div>
                <div class="education-cont">
                    <div class="half-cont">
                        <div class="vi-item">
                            <div class="table-title">就读学校:</div>
                            <div class="title-cont">{{ item2.college }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">主修科目:</div>
                            <div class="title-cont">{{ item2.professional }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">学位/资格:</div>
                            <div class="title-cont">
                                {{ item2.background }}
                            </div>
                        </div>
                    </div>
                    <div class="half-cont">
                        <div class="vi-item">
                            <div class="table-title">入学年月:</div>
                            <div class="title-cont">
                                {{ item2.admission_time | getDate }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">毕业年月:</div>
                            <div class="title-cont">
                                {{ item2.graduate_time | getDate }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">修读城市【上课地点】:</div>
                            <div v-if="item2.hasOwnProperty('school_address')" class="title-cont">
                                {{
                                    item2.school_address.area.length
                                        ? item2.school_address.area.join('')
                                        : item2.school_address.foreign
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 资格证书 -->
            <div v-for="(item3, index3) in fourth.qualification" :key="'key3' + index3">
                <div class="own">资格证书{{ index3 + 1 }}</div>
                <div class="education-cont">
                    <div class="half-cont">
                        <div class="vi-item">
                            <div class="table-title">颁授机构:</div>
                            <div class="title-cont">{{ item3.college }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">资格证书名称:</div>
                            <div class="title-cont">
                                {{ item3.qualification }}
                            </div>
                        </div>
                    </div>
                    <div class="half-cont">
                        <!-- <div class="vi-item">
                            <div class="table-title">颁授机构城市:</div>
                            <div class="title-cont">
                                {{
                                    !item3.collegeAddress.foreign
                                        ? item3.collegeAddress.area[0] +
                                            item3.collegeAddress.area[1]
                                        : item3.collegeAddress.foreign
                                }}
                            </div>
                        </div> -->
                        <div class="vi-item">
                            <div class="table-title">颁授年份:</div>
                            <div class="title-cont">
                                {{ item3.promulgation }}年
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="own">学历证书姓名及出生日期正确性</div>
            <div class="edu-vcont">
                <div class="f-gui">
                    <div class="if-title">
                        您的所有学历及资格证书上的姓名及出生日期，是否都与身份证上的完全相同？
                    </div>
                    <div class="is-yes">
                        {{ fourth.question==='1'? "是" : "否" }}
                    </div>
                </div>
            </div>
        </div>

        <!-- 就业记录(【获得学位证之后】的【全职】就业记录，兼职不需要填写) -->
        <div class="work-info">
            <div class="title-bar">就业记录 (【获得学位证之后】的【全职】就业记录，兼职不需要填写)</div>
            <div v-for="(item, index) in fifth.work" :key="'key-' + index" class="work-i">
                <div class="own">工作{{ index + 1 }}</div>
                <div class="education-cont">
                    <div class="half-cont">
                        <div class="vi-item">
                            <div class="table-title">雇主名称:</div>
                            <div class="title-cont">{{ item.company_name }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">工作地点:</div>
                            <div v-if="item.location_aboard === 0" class="title-cont">{{ item.location.area.join('') }}</div>
                            <div v-if="item.location_aboard === 1" class="title-cont">{{ item.location.foreign }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">职位:</div>
                            <div class="title-cont">{{ item.position }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">职责性质:</div>
                            <div class="title-cont">{{ item.duty }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">是否为高级管理职位:</div>
                            <div class="title-cont">{{ item.is_executives === 1 ? '是' : '否' }}</div>
                        </div>
                        <div v-if="item.is_executives == 1" class="vi-item">
                            <div class="table-title">高管证明文件:</div>
                            <div class="title-cont">{{ item.executives_certificate }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">工作职责:</div>
                            <div class="title-cont">{{ item.wduty }}</div>
                        </div>
                    </div>
                    <div class="half-cont">
                        <div class="vi-item">
                            <div class="table-title">入职时间:</div>
                            <div class="title-cont">
                                {{ item.entry_time | getDate }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">离职时间:</div>
                            <div v-if="item.departure==true" class="title-cont">至今</div>
                            <div v-else class="title-cont">
                                {{ item.departure_time | getDate }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">单位地址:</div>
                            <div class="title-cont">{{ item.location.details }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">工作证明文件:</div>
                            <div class="title-cont">{{ item.work_certificate }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">工作亮点:</div>
                            <div class="title-cont">{{ item.highlights }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">简历/工作总结:</div>
                            <div class="title-cont">
                                <div v-for="(ite, index) in item.file" :key="'key-' + index" class="file-item">
                                    <span class="flie-name">{{ ite.fileName }}</span><span class="flie-url" @click="resumeFileView(ite.fileUrl)">查看</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 在港的聘用公司的資料 -->
        <div class="family-member">
            <div class="title-bar">在港的聘用公司的资料</div>
            <div class="one-member">公司资料：</div>
            <div class="member-cont">
                <el-col :span="12">
                    <div class="vi-item">
                        <div class="table-title">公司名称:</div>
                        <div class="title-cont">{{ company.company }}</div>
                    </div>
                    <div class="vi-item">
                        <div class="table-title">电话:</div>
                        <div class="title-cont">{{ company.mobile }}</div>
                    </div>
                    <div class="vi-item">
                        <div class="table-title">传真:</div>
                        <div class="title-cont">{{ company.fax }}</div>
                    </div>
                    <div class="vi-item">
                        <div class="table-title">公司地址:</div>
                        <div class="title-cont">{{ company.address }}</div>
                    </div>
                    <div class="vi-item">
                        <div class="table-title">通讯地址:</div>
                        <div class="title-cont">{{ company.postal_address }}</div>
                    </div>
                    <div class="vi-item">
                        <div class="table-title">业务性质:</div>
                        <div class="title-cont">{{ company.business }}</div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="vi-item">
                        <div class="table-title">联络人:</div>
                        <div class="title-cont">{{ company.contact }}</div>
                    </div>
                    <div class="vi-item">
                        <div class="table-title">邮箱:</div>
                        <div class="title-cont">{{ company.email }}</div>
                    </div>
                    <div class="vi-item">
                        <div class="table-title">网址:</div>
                        <div class="title-cont">{{ company.website }}</div>
                    </div>
                    <div class="vi-item">
                        <div class="table-title">开业日期:</div>
                        <div class="title-cont">{{ company.open_date | getDate }}</div>
                    </div>
                    <div class="vi-item">
                        <div class="table-title">是否为附属公司:</div>
                        <div class="title-cont">{{ company.is_subsidiary==1?'是':'否' }}</div>
                    </div>
                    <div v-if="company.is_subsidiary==1" class="vi-item">
                        <div class="table-title">母公司名称:</div>
                        <div class="title-cont">{{ company.parent_company }}</div>
                    </div>

                    <div v-if="company.is_subsidiary==1" class="vi-item">
                        <div class="table-title">母公司地址:</div>
                        <div class="title-cont">{{ company.parent_company_address }}</div>
                    </div>
                </el-col>
            </div>
            <div class="one-member">公司的财政状况：</div>
            <div class="company-finance">
                <div>年份：<span>{{ company.one_start_year }}</span>年至<span>{{ company.one_end_year }}</span>年，港币(HKS)：<span>{{ company.one_money }}</span></div>
                <div>年份：<span>{{ company.two_start_year }}</span>年至<span>{{ company.two_end_year }}</span>年，港币(HKS)：<span>{{ company.two_money }}</span></div>
            </div>
            <div class="one-member">公司现时在本港聘用的雇员人数：</div>
            <div class="company-person">
                <div>截至 {{ company.employees }}年</div>
                <div>1)专业、管理及督导阶层雇员人数，本地雇員：<span>{{ company.location_employees }}</span>人，非本地雇員：<span> {{ company.other_employees }}</span>人</div>
                <div>2)其他雇员人数，本地雇員：<span>{{ company.other_employees_in }}</span>人，非本地雇員：<span>{{ company.other_employees_out }}</span>人</div>
            </div>
            <div class="one-member">公司在本港聘用非本地的雇员资料：</div>
            <div class="company-noperson">
                <div class="que">1) 在紧接本申请前的十八个月内，公司曾否为非本地雇员成功申请工作或受训签证/进入许可？</div>
                <div class="ans">
                    <span class="fontwei">{{ company.entry_permit==1?'有':'沒有' }}</span>
                    <span v-if="company.entry_permit==1">请提供最近受聘或受训的非本地雇员在本处的申请档案号码：</span>
                    <span class="fontwei">{{ company.file_number }}</span>
                </div>
                <div class="que">2) 在紧接本申请前的十八个月内，公司曾否根据补充劳工计划向劳工处递交输入劳工来港就业申请？</div>
                <div class="ans">
                    <span class="fontwei">{{ company.employment_application==1?'有':'沒有' }}</span>
                    <div v-if="company.employment_application==1" class="person-table">
                        <div class="half-cont">
                            <div class="vi-item">
                                <div class="table-title">申请档案号码:</div>
                                <div class="title-cont">{{ company.employment_file_number }}</div>
                            </div>
                            <div class="vi-item">
                                <div class="table-title">申请职位名称:</div>
                                <div class="title-cont">{{ company.job_title }}</div>
                            </div>
                            <div class="vi-item">
                                <div class="table-title">申请结果:</div>
                                <div class="title-cont">{{ company.aplication_result==="0"?'未通过':'通过' }}</div>
                            </div>
                        </div>
                        <div class="half-cont">
                            <div class="vi-item">
                                <div class="table-title">申请劳工数目:</div>
                                <div class="title-cont">{{ company.worker_number }}</div>
                            </div>
                            <div class="vi-item">
                                <div class="table-title">申请日期:</div>
                                <div class="title-cont">{{ company.application_date | getDate }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="one-member">受聘雇员(申请人)拟在港担任职位的详情：</div>
            <div class="member-cont">
                <el-col :span="12">
                    <div class="vi-item">
                        <div class="table-title">雇主姓名：</div>
                        <div class="title-cont">{{ sixth.name }}</div>
                    </div>
                    <div class="vi-item">
                        <div class="table-title">拟给与雇员的月薪：</div>
                        <div class="title-cont">{{ sixth.salary }}</div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="vi-item">
                        <div class="table-title">职位名称：</div>
                        <div class="title-cont">{{ sixth.position }}</div>
                    </div>
                    <div class="vi-item">
                        <div class="table-title">是否为高级管理职位:</div>
                        <div class="title-cont">{{ sixth.is_executives === 1 ? '是' : '否' }}</div>
                    </div>
                    <div class="vi-item">
                        <div class="table-title">公司/集团内部调职：</div>
                        <div class="title-cont">{{ sixth.transfer==1?'是':'否' }}</div>
                    </div>
                </el-col>
            </div>
            <div class="other-welfare">
                <div class="tit">其他附带福利：</div>
                <div class="bg-gray">
                    {{ sixth.welfare }}
                </div>
                <div class="price">
                    <div>
                        <span>其他附带福利按月的总值：</span>
                        <span class="num">{{ sixth.other_welfare }}</span>
                    </div>
                    <div>
                        <span>薪酬福利按月的总值：</span>
                        <span class="num">{{ sixth.total_welfare }}</span>
                    </div>
                </div>
                <div class="tit">受聘雇员(申请人)拟在港担任职位的详情(续)Details of position offered to the employee (applicant) in Hong Kong (Continued):</div>
                <div class="bg-gray">{{ sixth.hold_position }}</div>
                <div class="tit">如拟聘用的职位是现有职位，请提供现时该职位的入职资格及薪酬。（如适用）:</div>
                <div class="bg-gray">{{ sixth.entry_qualification }}</div>
                <div class="tit">请说明需要雇用申请人来港就业的理由及本职位未能由本地雇员担任的原因。 (只适用于根据「一般就业政策」及「输入内地人才计划」)递交的申请:</div>
                <div class="bg-gray">{{ sixth.employment_reason }}</div>
            </div>
        </div>
    </div>
</template>

<script lang="javascript">
import { expertOrder } from "@/api/workfow.js";
export default {
    name: "PersonalInfo",
    components: {},
    data() {
        return {
            loading: true,
            first: {},
            second: [],
            third: [],
            fourth: {},
            fifth: {},
            sixth: {},
            company: {},
        };
    },
    created() {
        expertOrder({
            order_id: this.$store.state.user.orderId,
        }).then((res) => {
            this.loading = false;
            if (res.code === 200) {
                this.first = Object.freeze(res.data.first);
                this.second = Object.freeze(res.data.second);
                this.third = Object.freeze(res.data.third);
                this.fourth = Object.freeze(res.data.fourth);
                this.fifth = Object.freeze(res.data.fifth);
                this.company = Object.freeze(res.data.fifth.company);
                this.sixth = Object.freeze(res.data.sixth);
            }
        });
    },
    mounted() {},
    methods: {
        resumeFileView(url) {
            window.open(this.$utils.toOss(url))
        },
    },
};
</script>

<style lang="less" scoped>
.yourself {
    width: 100%;
    height: 36px;
    line-height: 36px;
    background: #409eff;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    padding-left: 16px;
    border-radius: 4px 4px 0px 0px;
}

.yourself-table {
    width: 100%;
    min-height: 268px;
    border: 1px solid #dcdfe6;
    border-top: none;
    margin-bottom: 24px;
    overflow: auto;
}

.vi-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 14px;
    margin-top: 14px;
    padding-left: 16px;
}

.table-title {
    font-size: 14px;
    font-weight: 400;
    color: #303133;
    width: 137px;
}

.title-cont {
    font-size: 14px;
    font-weight: 400;
    color: #606266;
    width: 305px;
}

.title-bar {
    width: 100%;
    height: 36px;
    line-height: 36px;
    background: #409eff;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    padding-left: 16px;
    border-radius: 4px 4px 0px 0px;
}

.one-member {
    height: 36px;
    line-height: 36px;
    border-bottom: 1px solid #dcdfe6;
    border-top: 1px solid #dcdfe6;
    padding-left: 16px;
    font-size: 14px;
    font-weight: 600;
    color: #303133;
}

.member-cont {
    min-height: 50px;
    overflow: auto;
}

.own {
    width: 100%;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    font-weight: 600;
    color: #303133;
    padding-left: 16px;
    border-bottom: 1px solid #dcdfe6;
}

.f-gui {
    width: 100%;
    height: 74px;
    padding-left: 16px;
    border-bottom: 1px solid #ebeef5;
}

.if-title {
    font-size: 14px;
    font-weight: 400;
    color: #606266;
    line-height: 46px;
}

.quali-table {
    width: 100%;
    border: 1px solid #dcdfe6;
    border-top: none;
    margin-bottom: 24px;
}

.education-cont {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid #dcdfe6;
}

.education {
    border: 1px solid #dcdfe6;
    border-top: none;
    border-bottom: none;
    margin-bottom: 24px;
}

.half-cont {
    width: 50%;
}

.work-info {
    border: 1px solid #dcdfe6;
    border-top: none;
    border-bottom: none;
    margin-bottom: 24px;
}

.family-member {
    margin-bottom: 24px;
    border: 1px solid #dcdfe6;
}

.company-finance {
    padding: 0 16px;
    margin: 14px 0;

    div {
        display: flex;
        align-items: center;
        color: #303133;
        font-size: 14px;
        margin-bottom: 8px;
        &::before {
            content: "*";
            color: #f56c6c;
            margin-right: 8px;
        }
        span {
            color: #409eff;
            margin: 0 8px;
        }
    }
}

.company-person {
    padding: 0 16px;
    margin: 14px 0;
    div {
        color: #303133;
        margin-bottom: 8px;
        font-size: 14px;
    }
    span {
        color: #409eff;
        margin: 0 8px;
    }
}

.company-noperson {
    padding: 0 16px;
    margin: 14px 0;
    > div {
        color: #303133;
        margin-bottom: 8px;
        font-size: 14px;
        margin-bottom: 10px;
    }
    .ans {
        padding-left: 17px;
        color: #606266;
        margin-bottom: 20px;
        .vi-item {
            margin: 20px 0;
        }
        span {
            font-size: 14px;
            margin-right: 10px;
        }
    }

    .person-table {
        padding-left: 17px;
        margin: 15px 0;
        width: 100%;
        background: #f5f7fa;
        box-sizing: border-box;
        display: flex;
        align-items: flex-start;
    }
}

.other-welfare {
    padding: 0 16px;
    margin: 14px 0;
    width: 100%;
    font-size: 14px;
    .tit {
        border-top: 1px solid #ebeef5;
        padding-top: 20px;
        font-size: 14px;
    }
    .bg-gray {
        padding: 20px;
        box-sizing: border-box;
        background: #f5f7fa;
        margin: 10px 0 20px;
        font-size: 14px;
        color: #303133;
        line-height: 25px;
    }
    .price {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 20px;
        div {
            width: 50%;
            span {
                font-size: 14px;
            }
            .num {
                color: #606266;
            }
        }
    }
}
.fontwei {
    font-weight: bold;
}
.file-item {
    margin-bottom: 10px;
}

.flie-url {
    cursor: pointer;
    color: #409eff;
    margin-left: 15px;
}
</style>
