<!--
 * @Author: vinota
 * @Description: 个人信息表(优才)
-->
<template>
    <div>
        <!-- <router-link to="/OtherPage">跳转页</router-link> -->
        <div class="yourself">主申请人</div>
        <!-- 主申请人自己 -->
        <div class="yourself-table">
            <el-col :span="12">
                <div class="vi-item">
                    <div class="table-title">主申姓名:</div>
                    <div class="title-cont">{{ information.username }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">曾用名:</div>
                    <div class="title-cont">{{ information.usedName }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">性别:</div>
                    <div class="title-cont">
                        {{ information.sex == 1 ? "男" : "女" }}
                    </div>
                </div>
                <div class="vi-item">
                    <div class="table-title">出生日期:</div>
                    <div class="title-cont">
                        {{ information.birthday | getDate }}
                    </div>
                </div>
                <div class="vi-item">
                    <div class="table-title">出生城市:</div>
                    <div class="title-cont">
                        {{
                            !information.birthPlace.foreign
                                ? information.birthPlace.area[0] +
                                    information.birthPlace.area[1]
                                : information.birthPlace.foreign
                        }}
                    </div>
                </div>
                <div class="vi-item">
                    <div class="table-title">婚姻状况:</div>
                    <div class="title-cont">
                        {{ information.married | marryInfo }}
                    </div>
                </div>
                <div class="vi-item">
                    <div class="table-title">国籍:</div>
                    <div class="title-cont">{{ information.nationality }}</div>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="vi-item">
                    <div class="table-title">港澳通行证号码:</div>
                    <div class="title-cont">{{ information.passNumber }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">身份证号码:</div>
                    <div class="title-cont">{{ information.identityCard }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">手机号:</div>
                    <div class="title-cont">{{ information.mobile }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">邮箱:</div>
                    <div class="title-cont">{{ information.email }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">现定居国家:</div>
                    <div class="title-cont">{{ information.liveCountry }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">现居住地址:</div>
                    <div class="title-cont">
                        {{
                            !information.address.foreign
                                ? information.address.area[0] +
                                    information.address.area[1] +
                                    information.address.details
                                : information.address.foreign +
                                    information.address.details
                        }}
                    </div>
                </div>
            </el-col>
        </div>
        <!-- 家庭成员 -->
        <div class="family-member">
            <div class="title-bar">家庭成员</div>
            <div v-for="(Data, index1) in subsetList" :key="index1" class="one-fam">
                <div class="one-member">
                    {{ Data.relation | relationFilter }}
                </div>
                <div v-if="Data.relation!=='3'" class="member-cont">
                    <el-col :span="12">
                        <div class="vi-item">
                            <div class="table-title">姓名:</div>
                            <div class="title-cont">{{ Data.subsetName }}</div>
                        </div>
                        <div v-if="
                            Data.relation === '3' || Data.relation === '4'
                        " class="vi-item"
                        >
                            <div class="table-title">曾用名:</div>
                            <div class="title-cont">{{ Data.usedName }}</div>
                        </div>
                        <div v-if="
                            Data.relation === '4' || Data.relation === '5'
                        " class="vi-item"
                        >
                            <div class="table-title">性别:</div>
                            <div class="title-cont">
                                {{ Data.sex == 1 ? "男" : "女" }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">出生日期:</div>
                            <div class="title-cont">
                                {{ Data.birthday | getDate }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">出生城市:</div>
                            <div class="title-cont">
                                {{
                                    !Data.birthPlace.foreign
                                        ? Data.birthPlace.area[0] +
                                            Data.birthPlace.area[1]
                                        : Data.birthPlace.foreign
                                }}
                            </div>
                        </div>

                        <div v-if="Data.relation == '3'" class="vi-item">
                            <Tip :width="180">
                                <div slot="tipWord">
                                    <div>
                                        <div>仅有毕业证无效</div>
                                        <div>必须有学位证</div>
                                    </div>
                                </div>
                            </Tip>
                            <div class="table-title vi-wid">是否有学位证:</div>
                            <div class="title-cont">
                                {{ Data.hasDegree | hasDegreeFilter }}
                            </div>
                        </div>
                    </el-col>
                    <el-col v-if="
                        (Data.relation === '1' && Data.deceased === 1) ||
                            (Data.relation === '2' && Data.deceased === 1)
                    " :span="12"
                    >
                        <div class="vi-item">已故</div>
                    </el-col>
                    <el-col :span="12" :hidden="
                        (Data.relation === '1' && Data.deceased === 1) ||
                            (Data.relation === '2' && Data.deceased === 1)
                    "
                    >
                        <div v-if="
                            Data.relation == '1' ||
                                Data.relation == '2' ||
                                Data.relation == '5'
                        " class="vi-item"
                        >
                            <div class="table-title">婚姻状况:</div>
                            <div class="title-cont">{{ Data.married }}</div>
                        </div>
                        <div class="vi-item">
                            <!-- <Tip :width="180">
                                <div slot="tipWord">
                                    <div style="line-height: 35px">
                                        1、如已退休，请填写【退休】
                                    </div>
                                </div>
                            </Tip> -->
                            <div class="table-title vi-wid">职业:</div>
                            <div class="title-cont">{{ Data.occupation }}</div>
                        </div>
                        <div v-if="Data.occupation==='在职'" class="vi-item">
                            <div class="table-title">职业名称:</div>
                            <div class="title-cont">{{ Data.occupationName }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">目前居住国家:</div>
                            <div class="title-cont">{{ Data.liveCountry }}</div>
                        </div>
                        <div v-if="
                            Data.relation === '3' || Data.relation === '4'
                        " class="vi-item"
                        >
                            <div class="table-title">目前居住地址:</div>
                            <div class="title-cont">
                                {{
                                    !Data.address.foreign
                                        ? Data.address.area[0] +
                                            Data.address.area[1]
                                        : Data.address.foreign
                                }}
                            </div>
                        </div>
                        <div v-if="Data.hkIdentityCard && Data.hkIdentityCard !== ''" class="vi-item">
                            <div class="table-title" style="width:140px;">{{ Data.relation==="5"?"香港身份证号码:(永久居民)":"香港身份号码" }}(如有):</div>
                            <div class="title-cont">
                                {{ Data.hkIdentityCard }}
                            </div>
                        </div>
                        <div v-if="Data.relation == '3' || Data.relation == '4'" class="vi-item">
                            <!-- <Tip :width="196">
                                <div slot="tipWord">
                                    <div>
                                        <div>1、18周岁以上子女，不能随行</div>
                                        <div>2、如对子女无抚养权，不能随行</div>
                                    </div>
                                </div>
                            </Tip> -->
                            <div class="table-title vi-wid">是否随行:</div>
                            <div class="title-cont">
                                {{
                                    Data.follow == 0
                                        ? "待定"
                                        : Data.follow == 1
                                            ? "跟随"
                                            : "不跟随"
                                }}
                            </div>
                        </div>
                        <div v-if="Data.relation == '4'">
                            <div class="vi-item">
                                <div class="table-title vi-wid">国籍:</div>
                                <div class="title-cont">{{ Data.nationality }}</div>
                            </div>
                        </div>
                    </el-col>
                </div>
                <!-- 配偶 -->
                <div v-if="Data.relation==='3'" class="member-cont">
                    <el-col :span="12">
                        <div class="vi-item">
                            <div class="table-title">姓名:</div>
                            <div class="title-cont">{{ Data.subsetName }}</div>
                        </div>
                        <div v-if="
                            Data.relation === '3' || Data.relation === '4'
                        " class="vi-item"
                        >
                            <div class="table-title">曾用名:</div>
                            <div class="title-cont">{{ Data.usedName }}</div>
                        </div>
                        <div v-if="
                            Data.relation === '4' || Data.relation === '5'
                        " class="vi-item"
                        >
                            <div class="table-title">性别:</div>
                            <div class="title-cont">
                                {{ Data.sex == 1 ? "男" : "女" }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">出生日期:</div>
                            <div class="title-cont">
                                {{ Data.birthday | getDate }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">出生城市:</div>
                            <div class="title-cont">
                                {{
                                    !Data.birthPlace.foreign
                                        ? Data.birthPlace.area[0] +
                                            Data.birthPlace.area[1]
                                        : Data.birthPlace.foreign
                                }}
                            </div>
                        </div>

                        <div v-if="Data.relation == '3'" class="vi-item">
                            <Tip :width="180">
                                <div slot="tipWord">
                                    <div>
                                        <div>仅有毕业证无效</div>
                                        <div>必须有学位证</div>
                                    </div>
                                </div>
                            </Tip>
                            <div class="table-title vi-wid">是否有学位证:</div>
                            <div class="title-cont">
                                {{ Data.hasDegree | hasDegreeFilter }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title vi-wid">职业:</div>
                            <div class="title-cont">{{ Data.occupation }}</div>
                        </div>
                        <div v-if="Data.occupation==='在职'" class="vi-item">
                            <div class="table-title">职业名称:</div>
                            <div class="title-cont">{{ Data.occupationName }}</div>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="vi-item">
                            <div class="table-title vi-wid">港澳通行证号码:</div>
                            <div class="title-cont">{{ Data.passNumber }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title vi-wid">中国身份证号码:</div>
                            <div class="title-cont">{{ Data.identityCard }}</div>
                        </div>
                        <div v-if="Data.occupation==='在职'" class="vi-item">
                            <div class="table-title">职业名称:</div>
                            <div class="title-cont">{{ Data.occupationName }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">目前居住国家:</div>
                            <div class="title-cont">{{ Data.liveCountry }}</div>
                        </div>
                        <div v-if="
                            Data.relation === '3' || Data.relation === '4'
                        " class="vi-item"
                        >
                            <div class="table-title">目前居住地址:</div>
                            <div class="title-cont">
                                {{
                                    !Data.address.foreign
                                        ? Data.address.area[0] +
                                            Data.address.area[1]
                                        : Data.address.foreign
                                }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title" style="width:140px;">香港身份号码(如有):</div>
                            <div class="title-cont">
                                {{ Data.hkIdentityCard }}
                            </div>
                        </div>
                        <div v-if="Data.relation == '3' || Data.relation == '4'" class="vi-item">
                            <!-- <Tip :width="196">
                                <div slot="tipWord">
                                    <div>
                                        <div>1、18周岁以上子女，不能随行</div>
                                        <div>2、如对子女无抚养权，不能随行</div>
                                    </div>
                                </div>
                            </Tip> -->
                            <div class="table-title vi-wid">是否随行:</div>
                            <div class="title-cont">
                                {{
                                    Data.follow == 0
                                        ? "待定"
                                        : Data.follow == 1
                                            ? "跟随"
                                            : "不跟随"
                                }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title vi-wid">国籍:</div>
                            <div class="title-cont">{{ Data.nationality }}</div>
                        </div>
                    </el-col>
                </div>
            </div>
        </div>

        <!-- 基本资格 -->
        <div class="quali-table" style="margin-top: 24px">
            <div class="title-bar">基本资格</div>
            <div class="own">主申请人</div>
            <div class="f-gui">
                <div class="if-title">
                    1.是否曾在香港或其他地方因任何罪行或违法行为被定罪？
                </div>
                <div class="is-yes">
                    {{ question.hasConviction.has == "Y" ? "是" : "否"
                    }}<span v-if="question.hasConviction.has == 'Y'">
                        -
                        {{
                            question.hasConviction.has == "Y"
                                ? question.hasConviction.info
                                : ""
                        }}</span>
                </div>
            </div>
            <div class="f-gui">
                <div class="if-title">
                    2.是否曾在香港或其他地方被拒入境／递解／遣送或要求离境？
                </div>
                <div class="is-yes">
                    {{ question.hasDeparture.has == "Y" ? "是" : "否"
                    }}<span v-if="question.hasDeparture.has == 'Y'">
                        -
                        {{
                            question.hasDeparture.has == "Y"
                                ? question.hasDeparture.info
                                : ""
                        }}</span>
                </div>
            </div>
            <div class="f-gui">
                <div class="if-title">
                    3.是否曾被拒绝签发香港或其他地方的签证／进入许可？
                </div>
                <div class="is-yes">
                    {{ question.hasDeny.has == "Y" ? "是" : "否"
                    }}<span v-if="question.hasDeny.has == 'Y'">
                        -
                        {{
                            question.hasDeny.has == "Y"
                                ? question.hasDeny.info
                                : ""
                        }}</span>
                </div>
            </div>
            <div class="f-gui">
                <div class="if-title">
                    4.是否曾触犯香港或任何地方的入境法例？
                </div>
                <div class="is-yes">
                    {{ question.hasBreakingLaw.has == "Y" ? "是" : "否"
                    }}<span v-if="question.hasBreakingLaw.has == 'Y'">
                        -
                        {{
                            question.hasBreakingLaw.has == "Y"
                                ? question.hasBreakingLaw.info
                                : ""
                        }}</span>
                </div>
            </div>
            <div class="f-gui">
                <div class="if-title">
                    5.是否曾经使用另一个姓名或身份申请香港入境签证？
                </div>
                <div class="is-yes">
                    {{ question.hasOtherId.has == "Y" ? "是" : "否"
                    }}<span v-if="question.hasOtherId.has == 'Y'">
                        -
                        {{
                            question.hasOtherId.has == "Y"
                                ? question.hasOtherId.info
                                : ""
                        }}</span>
                </div>
            </div>
            <div class="f-gui">
                <div class="if-title">
                    6、是否办理过香港身份证？
                </div>
                <div class="is-yes">
                    {{ question.hasHkId.has == "Y" ? "是" : "否"
                    }}<span v-if="question.hasHkId.has == 'Y'">
                        -
                        {{
                            question.hasHkId.has == "Y"
                                ? question.hasHkId.info
                                : ""
                        }}</span>
                </div>
            </div>
            <div class="own">配偶</div>
            <div class="f-gui">
                <div class="if-title">1.是否现正在香港?</div>
                <div class="is-yes">
                    {{ question.spouseInHk.has == "Y" ? "是" : "否"
                    }}<span v-if="question.spouseInHk.has == 'Y'">
                        -
                        {{
                            question.spouseInHk.has == "Y"
                                ? question.spouseInHk.info
                                : ""
                        }}</span>
                </div>
            </div>
            <div class="f-gui">
                <div class="if-title">
                    2.是否曾被拒绝入境/递解/遣送或要求离开香港?
                </div>
                <div class="is-yes">
                    {{ question.spouseDeparture.has == "Y" ? "是" : "否"
                    }}<span v-if="question.spouseDeparture.has == 'Y'">
                        -
                        {{
                            question.spouseDeparture.has == "Y"
                                ? question.spouseDeparture.info
                                : ""
                        }}</span>
                </div>
            </div>
            <div class="f-gui">
                <div class="if-title">
                    3.是否曾被拒绝签发签证/进入许可以入境香港？
                </div>
                <div class="is-yes">
                    {{ question.spouseDeny.has == "Y" ? "是" : "否"
                    }}<span v-if="question.spouseDeny.has == 'Y'">
                        -
                        {{
                            question.spouseDeny.has == "Y"
                                ? question.spouseDeny.info
                                : ""
                        }}</span>
                </div>
            </div>
            <div class="f-gui">
                <div class="if-title">
                    4、是否办理过香港身份证？
                </div>
                <div class="is-yes">
                    {{ question.spouseHasHkId.has == "Y" ? "是" : "否"
                    }}<span v-if="question.spouseHasHkId.has == 'Y'">
                        -
                        {{
                            question.spouseHasHkId.has == "Y"
                                ? question.spouseHasHkId.info
                                : ""
                        }}</span>
                </div>
            </div>
            <div class="own">子女</div>
            <div v-for="(item, index) in question.childList" :key="index">
                <div class="f-gui">
                    <div class="if-title">1.是否现正在香港?</div>
                    <div class="is-yes">
                        {{ item.childInHk.has == "Y" ? "是" : "否"
                        }}<span v-if="item.childInHk.has == 'Y'">
                            -
                            {{
                                item.childInHk.has == "Y"
                                    ? item.childInHk.info
                                    : ""
                            }}</span>
                    </div>
                </div>
                <div class="f-gui">
                    <div class="if-title">
                        2.是否曾被拒绝入境/递解/遣送或要求离开香港?
                    </div>
                    <div class="is-yes">
                        {{ item.childDeparture.has == "Y" ? "是" : "否"
                        }}<span v-if="item.childDeparture.has == 'Y'">
                            -
                            {{
                                item.childDeparture.has == "Y"
                                    ? item.childDeparture.info
                                    : ""
                            }}</span>
                    </div>
                </div>
                <div class="f-gui">
                    <div class="if-title">
                        3.是否曾被拒绝签发签证/进入许可以入境香港？
                    </div>
                    <div class="is-yes">
                        {{ item.childDeny.has == "Y" ? "是" : "否"
                        }}<span v-if="item.childDeny.has == 'Y'">
                            -
                            {{
                                item.childDeny.has == "Y"
                                    ? item.childDeny.info
                                    : ""
                            }}</span>
                    </div>
                </div>
                <!-- <div class="f-gui">
                    <div class="if-title">4.是否需要英语培训？</div>
                    <div class="is-yes">
                        {{ item.childEnglishTraining.has == "Y" ? "是" : "否"
                        }}<span v-if="item.childEnglishTraining.has == 'Y'">
                            -
                            {{
                                item.childEnglishTraining.has == "Y"
                                    ? item.childEnglishTraining.info
                                    : ""
                            }}</span>
                    </div>
                </div> -->
                <div class="f-gui">
                    <div class="if-title">
                        4、是否为前次婚姻的子女？
                    </div>
                    <div class="is-yes">
                        {{ item.childStepchild.has == "Y" ? "是" : "否"
                        }}<span v-if="item.childStepchild.has == 'Y'">
                            -
                            {{
                                item.childStepchild.has == "Y"
                                    ? item.childStepchild.info
                                    : ""
                            }}</span>
                    </div>
                </div>
                <div class="f-gui">
                    <div class="if-title">
                        5、是否办理过香港身份证？
                    </div>
                    <div class="is-yes">
                        {{ item.childHasHkId.has == "Y" ? "是" : "否"
                        }}<span v-if="item.childHasHkId.has == 'Y'">
                            -
                            {{
                                item.childHasHkId.has == "Y"
                                    ? item.childHasHkId.info
                                    : ""
                            }}</span>
                    </div>
                </div>
            </div>
            <div class="own">家庭背景</div>
            <div class="f-gui">
                <div class="if-title">
                    1.至少一名直系家庭成员（已婚配偶、父母、兄弟姊妹、子女）是现居于香港的香港永久性居民
                </div>
                <div class="is-yes">
                    {{
                        question.backgroundMember.has == "Y" ? "满足" : "不满足"
                    }}
                </div>
            </div>
        </div>

        <!-- 语言能力 -->
        <div class="language">
            <div class="title-bar">语言能力</div>
            <div class="left-check">
                <div v-if="language > 0" class="radius-radio" />

                <div class="margin-check">
                    <div class="lan-text">{{ language | levelFilter }}</div>
                    <div class="lan-text">{{ language | languageFilter }}</div>
                </div>
            </div>
        </div>
        <!-- 主申请人大学及以上的【所有】学历及资格证书 -->
        <div class="education">
            <div class="title-bar">
                主申请人大学及以上的【所有】学历及资格证书
            </div>
            <!-- 学历 -->
            <div v-for="(item2, index2) in educationList" :key="'key2' + index2">
                <div class="own">学历{{ index2 + 1 }}</div>
                <div class="education-cont">
                    <div class="half-cont">
                        <div class="vi-item">
                            <div class="table-title">颁授机构:</div>
                            <div class="title-cont">{{ item2.college }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">授课类型:</div>
                            <div class="title-cont">{{ item2.type }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">专业:</div>
                            <div class="title-cont">
                                {{ item2.professional }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">修读城市:</div>
                            <div class="title-cont">
                                {{
                                    !item2.schoolAddress.foreign
                                        ? item2.schoolAddress.area[0] +
                                            item2.schoolAddress.area[1]
                                        : item2.schoolAddress.foreign
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="half-cont">
                        <div class="vi-item">
                            <div class="table-title">入学年月:</div>
                            <div class="title-cont">
                                {{ item2.admissionTime | getDate }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">毕业年月:</div>
                            <div class="title-cont">
                                {{ item2.graduateTime | getDate }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <Tip :width="200">
                                <div slot="tipWord">
                                    <div style="line-height: 35px">
                                        必须有学位证书
                                    </div>
                                </div>
                            </Tip>
                            <div class="table-title vi-wid">学位:</div>
                            <div class="title-cont">{{ item2.background }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">备注:</div>
                            <div class="title-cont">{{ item2.remark }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 资格证书 -->
            <div v-for="(item3, index3) in qualificationList" :key="'key3' + index3">
                <div class="own">资格证书{{ index3 + 1 }}</div>
                <div class="education-cont">
                    <div class="half-cont">
                        <div class="vi-item">
                            <div class="table-title">颁授机构:</div>
                            <div class="title-cont">{{ item3.college }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">资格证书名称:</div>
                            <div class="title-cont">
                                {{ item3.qualification }}
                            </div>
                        </div>
                    </div>
                    <div class="half-cont">
                        <div class="vi-item">
                            <div class="table-title">颁授机构城市:</div>

                            <div class="title-cont">
                                {{
                                    !item3.collegeAddress.foreign
                                        ? item3.collegeAddress.area[0] +
                                            item3.collegeAddress.area[1]
                                        : item3.collegeAddress.foreign
                                }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">颁授年份:</div>
                            <div class="title-cont">
                                {{ item3.promulgation }}年
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="own">学历证书姓名及出生日期正确性</div>
            <div class="edu-vcont">
                <div class="f-gui">
                    <div class="if-title">
                        您的所有学历及资格证书上的姓名及出生日期，是否都与身份证上的完全相同？
                    </div>
                    <div class="is-yes">
                        {{ order.qualification ? "是" : "否" }}
                    </div>
                </div>
                <div class="f-gui">
                    <div class="if-title">
                        您配偶的大学毕业证和学位证上的姓名及出生日期，是否都与身份证上的完全相同？
                    </div>
                    <div class="is-yes">{{ order.graduate ? "是" : "否" }}</div>
                </div>
            </div>
        </div>
        <!-- 工作信息 -->
        <div class="work-info">
            <div class="title-bar">工作信息</div>

            <div v-for="(item, index) in workList" :key="'key-' + index" class="work-i">
                <div class="own">工作{{ index + 1 }}</div>
                <div class="education-cont">
                    <div class="half-cont">
                        <div class="vi-item">
                            <div class="table-title">雇主名称:</div>
                            <div class="title-cont">{{ item.companyName }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">工作地点:</div>
                            <div class="title-cont">
                                {{
                                    !item.location.foreign
                                        ? item.location.area[0] +
                                            item.location.area[1]
                                        : item.location.foreign
                                }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">雇主所属机构性质:</div>
                            <div class="title-cont">{{ item.industry }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">职位:</div>
                            <div class="title-cont">{{ item.position }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">职位水平类别:</div>
                            <div v-if="item.professionalLevel === 6" class="title-cont">
                                其他 - {{ item.professionalOther }}
                            </div>
                            <div v-else class="title-cont">
                                {{
                                    item.professionalLevel
                                        | professionalLevelFilter
                                }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">高管证明文件:</div>
                            <div class="title-cont">{{ item.executivesCertificate }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">工作职责:</div>
                            <div class="title-cont">{{ item.wduty }}</div>
                        </div>
                    </div>
                    <div class="half-cont">
                        <div class="vi-item">
                            <div class="table-title">职责性质:</div>
                            <div class="title-cont">{{ item.duty }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">入职时间:</div>
                            <div class="title-cont">
                                {{ item.entryTime | getDate }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">离职时间:</div>
                            <div class="title-cont">
                                {{ item.departureTime | getDate }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">备注:</div>
                            <div class="title-cont">{{ item.remark }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">工作证明文件:</div>
                            <div class="title-cont">{{ item.workCertificate }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">工作亮点:</div>
                            <div class="title-cont">{{ item.highlights }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">简历/工作总结:</div>
                            <div class="title-cont">
                                <div v-for="(ite, index) in item.file" :key="'key-' + index" class="file-item">
                                    <span class="flie-name">{{ ite.fileName }}</span><span class="flie-url" @click="resumeFileView(ite.fileUrl)">查看</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="own">工作经验</div>
            <div class="work-cont">
                <div class="f-gui">
                    <div class="if-title">
                        1.工作经验?
                    </div>
                    <div class="is-yes">
                        {{ order.workExperience | workExperienceFilter }}
                    </div>
                </div>
                <div class="f-gui">
                    <div class="if-title">
                        2.拥有不少于2年相当于学位程度或专家水平的国际工作经验（需提供工作签证及护照上的出入境章）
                    </div>

                    <div class="is-yes">
                        {{
                            order.internationalExperience === 1
                                ? "满足"
                                : "不满足"
                        }}
                    </div>
                </div>
            </div>
        </div>
        <!-- 居住信息 -->
        <div class="live-info">
            <div class="title-bar">居住信息</div>
            <div class="live-top-title">
                自出生后曾居住12个月或更长时间的所有国家，【以护照上的出入境章为准】。如从未出国一年以上，则为“出生年/月～至今”。
            </div>
            <el-table :data="residenceList" border class="live-table">
                <el-table-column prop="country" label="国家" align="center" />
                <el-table-column prop="startTime" label="由（年/月）" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.startTime | getDate }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="endTime" label="至（年/月）" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.endTime | getDate }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 所属行业 -->
        <div class="industry-info">
            <div class="title-bar">所属行业</div>
            <div class="best-work">最能代表你的专业技能的行业是</div>
            <div>
                <div class="is-work">{{ industryKey }}</div>
                <div class="work-title">{{ industryVal }}</div>
            </div>
        </div>
        <!-- 辅助资料(选填) -->
        <div class="support-info">
            <div class="title-bar">辅助资料(选填)</div>
            <div class="f-gui">
                <div class="if-title">1.您是否有行业内的专业资格证书？</div>
                <div class="is-yes">
                    {{ order.professionalQualification | supplyFilter }}
                </div>
            </div>
            <div class="f-gui">
                <div class="if-title">
                    2.您是否在期刊或杂志上发表过论文、文章、书籍等（与工作、资质有关的）？
                </div>
                <div class="is-yes">{{ order.paper | supplyFilter }}</div>
            </div>
            <div class="f-gui">
                <div class="if-title">
                    3.您是否有大学奖学金证明、专利证书、获奖证书（工作单位颁发的可以）？
                </div>
                <div class="is-yes">{{ order.scholarship | supplyFilter }}</div>
            </div>

            <div class="f-gui">
                <div class="if-title">
                    4.您是否被媒体采访过，专访您的文章被发表在杂志上或网络上？
                </div>
                <div class="is-yes">
                    {{ order.mediaInterview | supplyFilter }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="javascript">
import Tip from "@/components/Tip/Tip.vue";
import { getInfo } from "@/api/workfow.js";
export default {
    name: "PersonalInfo",
    components: {
        Tip,
    },
    data() {
        return {
            information: {
                username: "",
                address: {
                    foreign: "",
                    area: ["", ""],
                    details: "",
                    country: "",
                },
                birthPlace: {
                    foreign: "",
                    area: ["", ""],
                    details: "",
                    country: "",
                },
            },
            subsetList: [],
            Data: {
                relation: "",
                birthPlace: {
                    foreign: "",
                    area: ["", ""],
                    details: "",
                    country: "",
                },
                address: {
                    foreign: "",
                    area: ["", ""],
                    details: "",
                    country: "",
                },
            },
            question: {
                hasConviction: "",
                hasDeparture: "",
                hasDeny: "",
                hasBreakingLaw: "",
                hasOtherId: "",
                hasHkId: "",
                spouseInHk: "",
                spouseDeparture: "",
                spouseDeny: "",
                spouseHasHkId: "",
                childInHk: "",
                childDeparture: "",
                childDeny: "",
                childEnglishTraining: "",
                backgroundMember: "",
                childStepchild: "",
                childHasHkId: ""
            },
            language: "",
            educationList: [],
            qualificationList: [],
            order: {
                qualification: false,
                graduate: false,
                workExperience: 0,
                internationalExperience: 0,
                profession: "jishuyuan",
                professionalQualification: 0,
                paper: 0,
                scholarship: 0,
                mediaInterview: 0,
            },
            workList: [],
            residenceList: [], //居住信息
            industryList: [
                {
                    ind: "学术研究及教育",
                    example: "幼儿教育、高等教育、学术研究",
                },
                {
                    ind: "建筑、测量、工程及建造",
                    example:
                        "园景设计、建筑、测量、土木/电机/机器/机构/环境工程",
                },
                {
                    ind: "艺术及文化",
                    example: "表演艺术、美术、博物馆、图书馆、摄影",
                },
                {
                    ind: "广播及娱乐",
                    example:
                        "广播及节目制作、电影、录像及电视制作、唱片及音乐出版",
                },
                {
                    ind: "业务支援及人力资源",
                    example:
                        "业务咨询顾问、公司秘书、行政支援、人事管理、招聘服务、公关关系服务、广告、信息服务",
                },
                {
                    ind: "餐饮服务及旅游",
                    example: "酒吧、餐厅、酒店、旅行代理",
                },
                {
                    ind: "商业及贸易",
                    example: "进出口、零售、批发",
                },
                {
                    ind: "金融及会计服务",
                    example: "会计、银行、保险、证券、投资银行",
                },
                {
                    ind: "医疗保健及兽医服务",
                    example:
                        "西医药、中医药、牙科服务、医学科技、职业治疗、护理、兽医服务",
                },
                {
                    ind: "资讯科技及电讯",
                    example: "资讯科技顾问、固网及无线通信",
                },
                {
                    ind: "法律服务",
                    example: "法律事务",
                },
                {
                    ind: "物流运输",
                    example: "物流、空运、海运、陆运",
                },
                {
                    ind: "工业制造",
                    example:
                        "电机、电子、食物及饮料、化工、金属、玩具、纺织、钟表、珠宝、印刷及出版",
                },
                {
                    ind: "体育运动",
                    example: "体育运动",
                },
                {
                    ind: "其他",
                    example: "",
                },
            ],
            industryKey: "", //所属行业左
            industryVal: "", //所属行业描述
        };
    },
    created() {
        getInfo({
            orderId: this.$store.state.user.orderId,
            // orderId: 2022125,
        }).then((res) => {
            this.information = Object.freeze(res.data.information);
            this.subsetList = res.data.subsetList;
            this.question = Object.freeze(res.data.question);
            this.language = res.data.order.languageLevel;
            this.educationList = Object.freeze(res.data.educationList);
            this.qualificationList = Object.freeze(res.data.qualificationList);
            this.order = res.data.order;
            this.workList = Object.freeze(res.data.workList);
            this.residenceList = Object.freeze(res.data.residenceList);

            // 判断所属行业如果是其他 就把值给数组
            if (this.order.profession === "15") {
                this.industryKey =
                    this.industryList[Number(this.order.profession) - 1].ind;
                this.industryVal = this.order.professionOther;
            } else {
                this.industryKey =
                    this.industryList[Number(this.order.profession) - 1].ind;
                this.industryVal =
                    this.industryList[
                        Number(this.order.profession) - 1
                    ].example;
            }

            this.subsetList.map((item, index) => {
                if (item.address == null) {
                    item.address = {
                        foreign: "",
                        area: ["", ""],
                        details: "",
                        country: "",
                    };
                }
            });
        });
    },
    mounted() {
    },
    methods: {
        resumeFileView(url) {
            window.open(this.$utils.toOss(url))
        },
    },
};
</script>

<style lang="less" scoped>
.yourself {
    width: 100%;
    height: 36px;
    line-height: 36px;
    background: #409eff;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    padding-left: 16px;
    border-radius: 4px 4px 0px 0px;
}

.yourself-table {
    width: 100%;
    min-height: 268px;
    border: 1px solid #dcdfe6;
    border-top: none;
    margin-bottom: 24px;
    overflow: auto;
}

.vi-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 14px;
    margin-top: 14px;
    padding-left: 16px;
}

.table-title {
    font-size: 14px;
    font-weight: 400;
    color: #303133;
    width: 120px;
}

.title-cont {
    font-size: 14px;
    font-weight: 400;
    color: #606266;
    width: 305px;
}

.title-bar {
    width: 100%;
    height: 36px;
    line-height: 36px;
    background: #409eff;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    padding-left: 16px;
    border-radius: 4px 4px 0px 0px;
}

.one-member {
    height: 36px;
    line-height: 36px;
    border: 1px solid #dcdfe6;
    border-top: none;
    padding-left: 16px;
    font-size: 14px;
    font-weight: 600;
    color: #303133;
}

.member-cont {
    min-height: 140px;
    border: 1px solid #dcdfe6;
    border-top: none;
    overflow: auto;
}

.vi-height {
    height: 245px;
}

.own {
    width: 100%;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    font-weight: 600;
    color: #303133;
    padding-left: 16px;
    border-bottom: 1px solid #dcdfe6;
}

.f-gui {
    width: 100%;
    height: 74px;
    padding-left: 16px;
    border-bottom: 1px solid #ebeef5;
}

.if-title {
    font-size: 14px;
    font-weight: 400;
    color: #606266;
    line-height: 46px;
}

.quali-table {
    width: 100%;
    border: 1px solid #dcdfe6;
    border-top: none;
    margin-bottom: 24px;
}

.left-check {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #dcdfe6;
    border-top: none;
}

.margin-check {
    padding-left: 16px;
}

.lan-text {
    font-size: 14px;
    font-weight: 400;
    color: #606266;
    line-height: 25px;
}

.language {
    margin-bottom: 24px;
}

.education-cont {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid #dcdfe6;
}

.education {
    border: 1px solid #dcdfe6;
    border-top: none;
    border-bottom: none;
    margin-bottom: 24px;
}

.half-cont {
    width: 50%;
}

.work-info {
    border: 1px solid #dcdfe6;
    border-top: none;
    border-bottom: none;
    margin-bottom: 24px;
}

.start-time {
    display: inline-block;
    width: 200px;
}

.end-time {
    display: inline-block;
    width: 200px;
}

.is-work {
    display: inline-block;
    width: 212px;
    line-height: 32px;
    font-size: 14px;
    font-weight: 400;
    color: #303133;
    border-right: 1px solid #dcdfe6;
    padding-left: 16px;
}

.work-title {
    display: inline-block;
    line-height: 32px;
    font-size: 14px;
    font-weight: 400;
    color: #606266;
    padding-left: 16px;
}

.best-work {
    line-height: 36px;
    font-size: 14px;
    font-weight: 400;
    color: #303133;
    border-bottom: 1px solid #dcdfe6;
    padding-left: 16px;
}

.industry-info {
    border: 1px solid #dcdfe6;
    border-top: none;
    margin-bottom: 20px;
}

.live-top-title {
    line-height: 50px;
    font-size: 14px;
    font-weight: 400;
    color: #606266;
    padding-left: 16px;
}

.live-info {
    margin-bottom: 24px;
    border: 1px solid #dcdfe6;
    border-top: none;
}

.live-table {
    width: 95%;
    margin: 0 auto 20px;
}

.support-info {
    border-left: 1px solid #dcdfe6;
    border-right: 1px solid #dcdfe6;
    margin-bottom: 50px;
}

.radius-radio {
    background: url("~@/assets/img/radio.png") center center no-repeat;
    width: 23px;
    height: 16px;
    position: relative;
    top: 6px;
}

.vi-wid {
    width: 123px !important;
}

.file-item {
    margin-bottom: 10px;
}

.flie-url {
    cursor: pointer;
    color: #409eff;
    margin-left: 15px;
}
</style>
