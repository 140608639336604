<!--
 * @Author: Akko
 * @Description: 个人信息表(留学)
-->
<template>
    <div v-loading="loading">
        <div class="yourself">留学主申请人</div>
        <!-- 主申请人 -->
        <div class="yourself-table">
            <el-col :span="12">
                <div class="vi-item">
                    <div class="table-title">主申姓名:</div>
                    <div class="title-cont">{{ information.username }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">姓名拼音:</div>
                    <div class="title-cont">{{ information && information.username_pinyin ? information.username_pinyin.family_name + ' ' + information.username_pinyin.given_name : '' }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">曾用名:</div>
                    <div class="title-cont">{{ information.usedName }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">性别:</div>
                    <div class="title-cont">
                        {{ information.sex | sexInfo }}
                    </div>
                </div>
                <div class="vi-item">
                    <div class="table-title">出生日期:</div>
                    <div class="title-cont">
                        <!-- {{ information.birthday | getDate }}（年龄：{{ information.age }}岁） -->
                        {{ formatDate(information.birthday) }}
                    </div>
                </div>
                <div class="vi-item">
                    <div class="table-title">在定居国家/地区的停留时间:</div>
                    <div class="title-cont">
                        <!-- {{ information.birthday | getDate }}（年龄：{{ information.age }}岁） -->
                        {{ formatStay(information.birthday) }}
                    </div>
                </div>
                <div class="vi-item">
                    <div class="table-title">出生城市:</div>
                    <div class="title-cont">
                        {{
                            information.birthPlaceAboard===0? information.birthPlace.area[0]+information.birthPlace.area[1]:
                            information.birthPlace.foreign
                        }}
                    </div>
                </div>
                <div class="vi-item">
                    <div class="table-title">婚姻状况:</div>
                    <div class="title-cont">
                        {{ information.married | marryInfo }}
                    </div>
                </div>
                <div class="vi-item">
                    <div class="table-title">婚前姓氏:</div>
                    <div class="title-cont">
                        {{ information.surname }}
                    </div>
                </div>
                <!-- <div class="vi-item">
                    <div class="table-title">雅思托福成绩:</div>
                    <div class="title-cont">
                        {{ information.ieltsOrToeflScore }}
                    </div>
                </div> -->
                <div class="vi-item">
                    <div class="table-title">国籍:</div>
                    <div class="title-cont">{{ information.nationalityType===1?'中国':'国外 - '+information.nationality }}</div>
                </div>
                <div v-for="(item, index) in information.foreignLanguageMark" :key="index" class="all-english-type">
                    <div class="vi-item">
                        <div class="table-title">成绩类型：</div>
                        <div class="title-cont">{{ item.type }}</div>
                    </div>
                    <div v-if="item.type !== '无成绩'" class="vi-item">
                        <div class="table-title">成绩分数：</div>
                        <div class="title-cont">{{ item.total_score }}</div>
                    </div>
                    <template v-if="item.type === '雅思'">
                        <div class="vi-item">
                            <div class="table-title">听：</div>
                            <div class="title-cont">{{ item.listen_score }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">说：</div>
                            <div class="title-cont">{{ item.speak_score }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">读：</div>
                            <div class="title-cont">{{ item.read_score }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">写：</div>
                            <div class="title-cont">{{ item.write_score }}</div>
                        </div>
                    </template>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="vi-item">
                    <div class="table-title">港澳通行证号码:</div>
                    <div class="title-cont">{{ information.passNumber }}{{ information.isHandled==='1'?'(暂未办理)':'' }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">身份证号码:</div>
                    <div class="title-cont">{{ information.identityCard }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">香港身份证号码:</div>
                    <div class="title-cont">{{ information.has_hk_id.info }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">手机号:</div>
                    <div class="title-cont">{{ information.mobile }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">邮箱:</div>
                    <div class="title-cont">{{ information.email }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">现定居国家:</div>
                    <div class="title-cont">{{ information.liveCountry }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">其他英语资格:</div>
                    <div class="title-cont">{{ information.englishSeniorityType===1?'CTE-6 - '+information.seniorityName:'其他 - '+information.seniorityName }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">现居住地址:</div>
                    <div class="title-cont">{{ information.addressAboard===0? information.address.area[0]+information.address.area[1]:information.address.details }}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">邮编:</div>
                    <div class="title-cont">{{ information.postcode}}</div>
                </div>
                <div class="vi-item">
                    <div class="table-title">预计入学日期:</div>
                    <div class="title-cont">{{ (information.enrollmentDate && information.enrollmentDate.date && information.enrollmentDate.quarter) ? information.enrollmentDate.date + '年' + information.enrollmentDate.quarter : "" }}</div>
                </div>
            </el-col>
        </div>

        <!-- 家庭成员 -->
        <div class="family-member">
            <div class="title-bar">家庭成员</div>
            <div v-for="(Data, index1) in subsetList" :key="index1" class="one-fam">
                <div class="one-member">
                    {{ Data.relation | relationFilter }}
                </div>
                <div v-if="Data.relation!=='3'" class="member-cont">
                    <el-col :span="12">
                        <div class="vi-item">
                            <div class="table-title">姓名:</div>
                            <div class="title-cont">{{ Data.subsetName }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">姓名拼音:</div>
                            <div class="title-cont">{{ Data.subset_name_pinyin ? Data.subset_name_pinyin.family_name + ' ' + Data.subset_name_pinyin.given_name : '' }}</div>
                        </div>
                        <div v-if="
                            Data.relation === '3' || Data.relation === '4'
                        " class="vi-item"
                        >
                            <div class="table-title">曾用名:</div>
                            <div class="title-cont">{{ Data.usedName }}</div>
                        </div>
                        <div v-if="
                            Data.relation === '4' || Data.relation === '5'
                        " class="vi-item"
                        >
                            <div class="table-title">性别:</div>
                            <div class="title-cont">
                                {{ Data.sex == 1 ? "男" : "女" }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">出生日期:</div>
                            <div class="title-cont">
                                {{ Data.birthday | getDate }}（年龄：{{ Data.age }}岁）
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">出生城市:</div>
                            <div class="title-cont">
                                {{
                                    !Data.birthPlace.foreign
                                        ? Data.birthPlace.area[0] +
                                            Data.birthPlace.area[1]
                                        : Data.birthPlace.foreign
                                }}
                            </div>
                        </div>

                        <div v-if="Data.relation == '3'" class="vi-item">
                            <Tip :width="180">
                                <div slot="tipWord">
                                    <div>
                                        <div>仅有毕业证无效</div>
                                        <div>必须有学位证</div>
                                    </div>
                                </div>
                            </Tip>
                            <div class="table-title vi-wid">是否有学位证:</div>
                            <div class="title-cont">
                                {{ Data.hasDegree | hasDegreeFilter }}
                            </div>
                        </div>
                    </el-col>
                    <el-col v-if="
                        (Data.relation === '1' && Data.deceased === 1) ||
                            (Data.relation === '2' && Data.deceased === 1)
                    " :span="12"
                    >
                        <div class="vi-item">已故</div>
                    </el-col>
                    <el-col :span="12" :hidden="
                        (Data.relation === '1' && Data.deceased === 1) ||
                            (Data.relation === '2' && Data.deceased === 1)
                    "
                    >
                        <div v-if="
                            Data.relation == '1' ||
                                Data.relation == '2' ||
                                Data.relation == '5'
                        " class="vi-item"
                        >
                            <div class="table-title">婚姻状况:</div>
                            <div class="title-cont">{{ Data.married }}</div>
                        </div>
                        <div class="vi-item">
                            <!-- <Tip :width="180">
                                <div slot="tipWord">
                                    <div style="line-height: 35px">
                                        1、如已退休，请填写【退休】
                                    </div>
                                </div>
                            </Tip> -->
                            <div class="table-title vi-wid">职业:</div>
                            <div class="title-cont">{{ Data.occupation }}</div>
                        </div>
                        <div v-if="Data.occupation==='在职'" class="vi-item">
                            <div class="table-title">职业名称:</div>
                            <div class="title-cont">{{ Data.occupationName }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">目前居住国家:</div>
                            <div class="title-cont">{{ Data.liveCountry }}</div>
                        </div>
                        <div v-if="
                            Data.relation === '3' || Data.relation === '4'
                        " class="vi-item"
                        >
                            <div class="table-title">目前居住地址:</div>
                            <div class="title-cont">
                                {{
                                    !Data.address.foreign
                                        ? Data.address.area[0] +
                                            Data.address.area[1]
                                        : Data.address.foreign
                                }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">邮箱:</div>
                            <div class="title-cont">{{ Data.postcode }}</div>
                        </div>
                        <div v-if="Data.hkIdentityCard && Data.hkIdentityCard !== ''" class="vi-item">
                            <div class="table-title">{{ Data.relation==="5"?"香港身份证号码:(永久居民)":"香港身份号码" }}(如有):</div>
                            <div class="title-cont">
                                {{ Data.hkIdentityCard }}
                            </div>
                        </div>
                        <div v-if="Data.relation == '3' || Data.relation == '4'" class="vi-item">
                            <!-- <Tip :width="196">
                                <div slot="tipWord">
                                    <div>
                                        <div>1、18周岁以上子女，不能随行</div>
                                        <div>2、如对子女无抚养权，不能随行</div>
                                    </div>
                                </div>
                            </Tip> -->
                            <div class="table-title vi-wid">是否随行:</div>
                            <div class="title-cont">
                                {{
                                    Data.follow == 0
                                        ? "待定"
                                        : Data.follow == 1
                                            ? "跟随"
                                            : "不跟随"
                                }}
                            </div>
                        </div>
                    </el-col>
                </div>
                <!-- 配偶 -->
                <div v-if="Data.relation==='3'" class="member-cont">
                    <el-col :span="12">
                        <div class="vi-item">
                            <div class="table-title">姓名:</div>
                            <div class="title-cont">{{ Data.subsetName }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">姓名拼音:</div>
                            <div class="title-cont" style="word-break: break-all;">{{ Data.subset_name_pinyin ? Data.subset_name_pinyin.family_name + ' ' + Data.subset_name_pinyin.given_name : '' }}</div>
                        </div>
                        <div v-if="
                            Data.relation === '3' || Data.relation === '4'
                        " class="vi-item"
                        >
                            <div class="table-title">曾用名:</div>
                            <div class="title-cont">{{ Data.usedName }}</div>
                        </div>
                        <div v-if="
                            Data.relation === '4' || Data.relation === '5'
                        " class="vi-item"
                        >
                            <div class="table-title">性别:</div>
                            <div class="title-cont">
                                {{ Data.sex == 1 ? "男" : "女" }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">出生日期:</div>
                            <div class="title-cont">
                                {{ Data.birthday | getDate }}（年龄：{{ Data.age }}岁）
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">出生城市:</div>
                            <div class="title-cont">
                                {{
                                    !Data.birthPlace.foreign
                                        ? Data.birthPlace.area[0] +
                                            Data.birthPlace.area[1]
                                        : Data.birthPlace.foreign
                                }}
                            </div>
                        </div>

                        <div v-if="Data.relation == '3'" class="vi-item">
                            <Tip :width="180">
                                <div slot="tipWord">
                                    <div>
                                        <div>仅有毕业证无效</div>
                                        <div>必须有学位证</div>
                                    </div>
                                </div>
                            </Tip>
                            <div class="table-title vi-wid">是否有学位证:</div>
                            <div class="title-cont">
                                {{ Data.hasDegree | hasDegreeFilter }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title vi-wid">职业:</div>
                            <div class="title-cont">{{ Data.occupation }}</div>
                        </div>
                        <div v-if="Data.occupation==='在职'" class="vi-item">
                            <div class="table-title">职业名称:</div>
                            <div class="title-cont">{{ Data.occupationName }}</div>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="vi-item">
                            <div class="table-title vi-wid">港澳通行证号码:</div>
                            <div class="title-cont">{{ Data.passNumber }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title vi-wid">中国身份证号码:</div>
                            <div class="title-cont">{{ Data.identityCard }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">目前居住国家:</div>
                            <div class="title-cont">{{ Data.liveCountry }}</div>
                        </div>
                        <div v-if="
                            Data.relation === '3' || Data.relation === '4'
                        " class="vi-item"
                        >
                            <div class="table-title">目前居住地址:</div>
                            <div class="title-cont">
                                {{
                                    !Data.address.foreign
                                        ? Data.address.area[0] +
                                            Data.address.area[1]
                                        : Data.address.foreign
                                }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">邮箱:</div>
                            <div class="title-cont">{{ Data.postcode }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title" style="width:140px;">香港身份号码(如有):</div>
                            <div class="title-cont">
                                {{ Data.hkIdentityCard }}
                            </div>
                        </div>
                        <div v-if="Data.relation == '3' || Data.relation == '4'" class="vi-item">
                            <!-- <Tip :width="196">
                                <div slot="tipWord">
                                    <div>
                                        <div>1、18周岁以上子女，不能随行</div>
                                        <div>2、如对子女无抚养权，不能随行</div>
                                    </div>
                                </div>
                            </Tip> -->
                            <div class="table-title vi-wid">是否随行:</div>
                            <div class="title-cont">
                                {{
                                    Data.follow == 0
                                        ? "待定"
                                        : Data.follow == 1
                                            ? "跟随"
                                            : "不跟随"
                                }}
                            </div>
                        </div>
                    </el-col>
                </div>
            </div>
        </div>

        <!-- 基本资格 -->
        <div class="quali-table" style="margin-top: 24px">
            <div class="title-bar">基本资格</div>
            <div class="own">主申请人</div>
            <div class="f-gui">
                <div class="if-title">
                    1.是否曾在香港或其他地方因任何罪行或违法行为被定罪？
                </div>
                <div class="is-yes">
                    {{ question.hasConviction.has == "Y" ? "是" : "否"
                    }}<span v-if="question.hasConviction.has == 'Y'">
                        -
                        {{
                            question.hasConviction.has == "Y"
                                ? question.hasConviction.info
                                : ""
                        }}</span>
                </div>
            </div>
            <div class="f-gui">
                <div class="if-title">
                    2.是否曾在香港或其他地方被拒入境／递解／遣送或要求离境？
                </div>
                <div class="is-yes">
                    {{ question.hasDeparture.has == "Y" ? "是" : "否"
                    }}<span v-if="question.hasDeparture.has == 'Y'">
                        -
                        {{
                            question.hasDeparture.has == "Y"
                                ? question.hasDeparture.info
                                : ""
                        }}</span>
                </div>
            </div>
            <div class="f-gui">
                <div class="if-title">
                    3.是否曾被拒绝签发香港或其他地方的签证／进入许可？
                </div>
                <div class="is-yes">
                    {{ question.hasDeny.has == "Y" ? "是" : "否"
                    }}<span v-if="question.hasDeny.has == 'Y'">
                        -
                        {{
                            question.hasDeny.has == "Y"
                                ? question.hasDeny.info
                                : ""
                        }}</span>
                </div>
            </div>
            <div class="f-gui">
                <div class="if-title">
                    4.是否曾触犯香港或任何地方的入境法例？
                </div>
                <div class="is-yes">
                    {{ question.hasBreakingLaw.has == "Y" ? "是" : "否"
                    }}<span v-if="question.hasBreakingLaw.has == 'Y'">
                        -
                        {{
                            question.hasBreakingLaw.has == "Y"
                                ? question.hasBreakingLaw.info
                                : ""
                        }}</span>
                </div>
            </div>
            <div class="f-gui">
                <div class="if-title">
                    5.是否曾经使用另一个姓名或身份申请香港入境签证？
                </div>
                <div class="is-yes">
                    {{ question.hasOtherId.has == "Y" ? "是" : "否"
                    }}<span v-if="question.hasOtherId.has == 'Y'">
                        -
                        {{
                            question.hasOtherId.has == "Y"
                                ? question.hasOtherId.info
                                : ""
                        }}</span>
                </div>
            </div>
            <!-- 配偶 -->
            <!-- <div v-if="information.married==1"> -->
            <div class="own">配偶</div>
            <div class="f-gui">
                <div class="if-title">1.是否现正在香港?</div>
                <div class="is-yes">
                    {{ question.spouseInHk.has == "Y" ? "是" : "否"
                    }}<span v-if="question.spouseInHk.has == 'Y'">
                        -
                        {{
                            question.spouseInHk.has == "Y"
                                ? question.spouseInHk.info
                                : ""
                        }}</span>
                </div>
            </div>
            <div class="f-gui">
                <div class="if-title">
                    2.是否曾被拒绝入境/递解/遣送或要求离开香港?
                </div>
                <div class="is-yes">
                    {{ question.spouseDeparture.has == "Y" ? "是" : "否"
                    }}<span v-if="question.spouseDeparture.has == 'Y'">
                        -
                        {{
                            question.spouseDeparture.has == "Y"
                                ? question.spouseDeparture.info
                                : ""
                        }}</span>
                </div>
            </div>
            <div class="f-gui">
                <div class="if-title">
                    3.是否曾被拒绝签发签证/进入许可以入境香港？
                </div>
                <div class="is-yes">
                    {{ question.spouseDeny.has == "Y" ? "是" : "否"
                    }}<span v-if="question.spouseDeny.has == 'Y'">
                        -
                        {{
                            question.spouseDeny.has == "Y"
                                ? question.spouseDeny.info
                                : ""
                        }}</span>
                </div>
            </div>
            <!-- </div> -->
            <div class="own">子女</div>
            <div class="f-gui">
                <div class="if-title">1.是否现正在香港?</div>
                <div class="is-yes">
                    {{ question.childInHk.has == "Y" ? "是" : "否"
                    }}<span v-if="question.childInHk.has == 'Y'">
                        -
                        {{
                            question.childInHk.has == "Y"
                                ? question.childInHk.info
                                : ""
                        }}</span>
                </div>
            </div>
            <div class="f-gui">
                <div class="if-title">
                    2.是否曾被拒绝入境/递解/遣送或要求离开香港?
                </div>
                <div class="is-yes">
                    {{ question.childDeparture.has == "Y" ? "是" : "否"
                    }}<span v-if="question.childDeparture.has == 'Y'">
                        -
                        {{
                            question.childDeparture.has == "Y"
                                ? question.childDeparture.info
                                : ""
                        }}</span>
                </div>
            </div>
            <div class="f-gui">
                <div class="if-title">
                    3.是否曾被拒绝签发签证/进入许可以入境香港？
                </div>
                <div class="is-yes">
                    {{ question.childDeny.has == "Y" ? "是" : "否"
                    }}<span v-if="question.childDeny.has == 'Y'">
                        -
                        {{
                            question.childDeny.has == "Y"
                                ? question.childDeny.info
                                : ""
                        }}</span>
                </div>
            </div>
            <!-- <div class="f-gui">
                <div class="if-title">4.是否需要英语培训？</div>
                <div class="is-yes">
                    {{ question.childEnglishTraining.has == "Y" ? "是" : "否"
                    }}<span v-if="question.childEnglishTraining.has == 'Y'">
                        -
                        {{
                            question.childEnglishTraining.has == "Y"
                                ? question.childEnglishTraining.info
                                : ""
                        }}</span>
                </div>
            </div> -->

            <div class="own">家庭背景</div>
            <div class="f-gui">
                <div class="if-title">
                    1.至少一名直系家庭成员（已婚配偶、父母、兄弟姊妹、子女）是现居于香港的香港永久性居民
                </div>
                <div class="is-yes">
                    {{
                        question.backgroundMember.has == "Y" ? "满足" : "不满足"
                    }}
                </div>
            </div>
            <div class="f-gui">
                <div class="if-title">
                    2.有随行已婚配偶，且配偶拥有学士或以上的学位证（仅毕业证，无学位证不算）
                </div>
                <div class="is-yes">
                    {{
                        question.backgroundFollow.has == "Y" ? "满足" : "不满足"
                    }}
                </div>
            </div>
            <div class="f-gui">
                <div class="if-title">
                    3.有随行的18岁以下子女
                </div>
                <div class="is-yes">
                    {{
                        question.backgroundAdult.has==='Y'?question.backgroundAdult.info:'不满足'
                    }}
                </div>
            </div>
        </div>

        <!-- 主申学历及资格证书 -->
        <div class="education">
            <div class="title-bar">
                主申学历及资格证书
            </div>
            <!-- 学历 -->
            <div v-for="(item2, index2) in educationList" :key="'key2' + index2">
                <div class="own">学历{{ index2 + 1 }}</div>
                <div class="education-cont">
                    <div class="half-cont">
                        <div class="vi-item">
                            <div class="table-title">颁授机构:</div>
                            <div class="title-cont">{{ item2.college }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">授课类型:</div>
                            <div class="title-cont">{{ item2.type }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">专业:</div>
                            <div class="title-cont">
                                {{ item2.professional }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">修读城市:</div>
                            <div class="title-cont">
                                {{ item2.schoolAddressAboard===0? item2.schoolAddress.area[0]+item2.schoolAddress.area[1]:item2.schoolAddress.foreign }}
                            </div>
                        </div>
                    </div>
                    <div class="half-cont">
                        <div class="vi-item">
                            <div class="table-title">入学年月:</div>
                            <div class="title-cont">
                                {{ item2.admissionTime | getDate }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">毕业年月:</div>
                            <div class="title-cont">
                                {{ item2.graduateTime | getDate }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">学位:</div>
                            <div class="title-cont">
                                {{ item2.background }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">在校平均分/满分:</div>
                            <div class="title-cont">
                                {{ item2.average }}
                            </div>
                        </div>
                        <!-- <div class="vi-item">
                            <div class="table-title">备注:</div>
                            <div class="title-cont">
                                {{ item2.remark }}
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <!-- 资格证书 -->
            <div v-for="(item3, index3) in qualificationList" :key="'key3' + index3">
                <div class="own">资格证书{{ index3 + 1 }}</div>
                <div class="education-cont">
                    <div class="half-cont">
                        <div class="vi-item">
                            <div class="table-title">颁授机构:</div>
                            <div class="title-cont">{{ item3.college }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">资格证书名称:</div>
                            <div class="title-cont">
                                {{ item3.qualification }}
                            </div>
                        </div>
                        <!-- <div class="vi-item">
                            <div class="table-title">备注:</div>
                            <div class="title-cont">{{ item3.remark }}</div>
                        </div> -->
                    </div>
                    <div class="half-cont">
                        <div class="vi-item">
                            <div class="table-title">颁授机构城市:</div>
                            <div class="title-cont">
                                {{
                                    !item3.collegeAddress.foreign
                                        ? item3.collegeAddress.area[0] +
                                            item3.collegeAddress.area[1]
                                        : item3.collegeAddress.foreign
                                }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">颁授年份:</div>
                            <div class="title-cont">
                                {{ item3.promulgation }}年
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="own">学历证书姓名及出生日期正确性</div>
            <div class="edu-vcont">
                <div class="f-gui">
                    <div class="if-title">
                        您的所有学历及资格证书上的姓名及出生日期，是否都与身份证上的完全相同？
                    </div>
                    <div class="is-yes">
                        {{ order.qualification==1? "是" : "否" }}
                    </div>
                </div>
                <div class="f-gui">
                    <div class="if-title">
                        您配偶的大学毕业证和学位证上的姓名及出生日期，是否都与身份证上的“完全相同”？
                    </div>
                    <div class="is-yes">
                        {{ order.graduate==1? "是" : "否" }}
                    </div>
                </div>
            </div>
        </div>

        <!-- 工作信息 -->
        <div class="work-info">
            <div class="title-bar">工作信息</div>

            <div v-for="(item, index) in workList" :key="'key-' + index" class="work-i">
                <div class="own">工作{{ index + 1 }}</div>
                <div class="education-cont">
                    <div class="half-cont">
                        <div class="vi-item">
                            <div class="table-title">雇主名称:</div>
                            <div class="title-cont">{{ item.companyName }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">工作地点:</div>
                            <div class="title-cont">
                                {{
                                    !item.location.foreign
                                        ? item.location.area[0] +
                                            item.location.area[1]
                                        : item.location.foreign
                                }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">雇主所属机构性质:</div>
                            <div class="title-cont">{{ item.industry }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">职位:</div>
                            <div class="title-cont">{{ item.position }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">职位水平类别:</div>
                            <div v-if="item.professionalLevel === 6" class="title-cont">
                                其他 - {{ item.professionalOther }}
                            </div>
                            <div v-else class="title-cont">
                                {{
                                    item.professionalLevel
                                        | professionalLevelFilter
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="half-cont">
                        <div class="vi-item">
                            <div class="table-title">职责性质:</div>
                            <div class="title-cont">{{ item.duty }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">入职时间:</div>
                            <div class="title-cont">
                                {{ item.entryTime | getDate }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">离职时间:</div>
                            <div class="title-cont">
                                {{ item.departureTime | getDate }}
                            </div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">工作职责:</div>
                            <div class="title-cont">{{ item.wduty }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">工作亮点:</div>
                            <div class="title-cont">{{ item.highlights }}</div>
                        </div>
                        <div class="vi-item">
                            <div class="table-title">简历/工作总结:</div>
                            <div class="title-cont">
                                <div v-for="(ite, index) in item.file" :key="'key-' + index" class="file-item">
                                    <span class="flie-name">{{ ite.fileName }}</span><span class="flie-url" @click="resumeFileView(ite.fileUrl)">查看</span>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="vi-item">
                            <div class="table-title">备注:</div>
                            <div class="title-cont">{{ item.remark }}</div>
                        </div> -->
                    </div>
                </div>
            </div>

            <div class="own">工作经验</div>
            <div class="work-cont">
                <div class="f-gui">
                    <div class="if-title">
                        1.不少于10年相当于学位程度或专家水平的工作经验，当中最少5年担任高级职位?
                    </div>
                    <div class="is-yes">
                        {{ order.workExperience | workExperienceFilter }}
                    </div>
                </div>
                <div class="f-gui">
                    <div class="if-title">
                        2.拥有不少于2年相当于学位程度或专家水平的国际工作经验（需提供工作签证及护照上的出入境章）
                    </div>

                    <div class="is-yes">
                        {{
                            order.internationalExperience === 1
                                ? "满足"
                                : "不满足"
                        }}
                    </div>
                </div>
            </div>
        </div>

        <!-- 主观性评价 -->
        <!-- <div class="family-member">
            <div class="title-bar">主观性评价</div>
            <div class="other-welfare">
                <div class="tit">以下请简述个人学习能力或专业特长的自我描述:</div>
                <div class="bg-gray">{{ subjectiveEvaluation.specialty }}</div>
                <div class="tit">简要说明大学专业所学和收获，以及参与的实践活动:</div>
                <div class="bg-gray">{{ subjectiveEvaluation.harvest }}</div>
                <div class="tit">简明填写您考虑香港留学的目的，对未来的职业选择和人生规划:</div>
                <div class="bg-gray">{{ subjectiveEvaluation.objective }}</div>
                <div class="tit">简单介绍您的兴趣，您所期望学习的专业，希望学到什么技能，或您所感兴趣的学校:</div>
                <div class="bg-gray">{{ subjectiveEvaluation.interest }}</div>
            </div>
        </div> -->
    </div>
</template>

<script lang="javascript">
import Tip from "@/components/Tip/Tip.vue";
import { studyInformation } from "@/api/workfow.js";
import dayjs from 'dayjs'
export default {
    name: "PersonalInfo",
    components: { Tip },
    data() {
        return {
            loading: true,
            educationList: [],
            qualificationList: [],
            residenceList: [],
            information: {},
            question: {},
            subsetList: [],
            workList: [],
            order: {},
            subjectiveEvaluation: {},
        };
    },
    created() {
        studyInformation({
            orderId:this.$store.state.user.orderId,
        }).then((res) => {
            this.loading = false;
            if (res.code === 200) {
                this.educationList = Object.freeze(res.data.educationList);
                this.information = Object.freeze(res.data.information);
                this.qualificationList = Object.freeze(
                    res.data.qualificationList
                );
                this.question = Object.freeze(res.data.question);
                this.residenceList = Object.freeze(res.data.residenceList);
                this.subsetList = Object.freeze(res.data.subsetList);
                this.workList = Object.freeze(res.data.workList);
                this.order = Object.freeze(res.data.order);
                this.subjectiveEvaluation = Object.freeze(
                    res.data.subjectiveEvaluation
                );
            }
        });
    },
    mounted() {},
    methods: {
        resumeFileView(url) {
            window.open(this.$utils.toOss(url))
        },
        formatDate(val) {
            const year = new Date(val).getFullYear()
            const month = new Date(val).getMonth() + 1
            const day = new Date(val).getDate()
            return `${day}日${month}月${year}年`
        },
        formatStay(val) {
            const startDate = dayjs(val, 'YYYY-MM-DD')
            const endDate = dayjs().format('YYYY-MM-DD')
            const year = dayjs(endDate).diff(startDate, 'years')
            const months = Math.floor(dayjs(endDate).diff(startDate, 'months') % 12)
            if (year === 0) {
                return `${months}个月`
            } else {
                return `${year}年${months}个月`
            }
        }
    },
};
</script>

<style lang="less" scoped>
.yourself {
    width: 100%;
    height: 36px;
    line-height: 36px;
    background: #409eff;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    padding-left: 16px;
    border-radius: 4px 4px 0px 0px;
}

.yourself-table {
    width: 100%;
    min-height: 268px;
    border: 1px solid #dcdfe6;
    border-top: none;
    margin-bottom: 24px;
    overflow: auto;
}

.vi-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 14px;
    margin-top: 14px;
    padding-left: 16px;
}

.table-title {
    font-size: 14px;
    font-weight: 400;
    color: #303133;
    width: 230px;
}

.title-cont {
    font-size: 14px;
    font-weight: 400;
    color: #606266;
    width: 305px;
}

.title-bar {
    width: 100%;
    height: 36px;
    line-height: 36px;
    background: #409eff;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    padding-left: 16px;
    border-radius: 4px 4px 0px 0px;
}

.one-member {
    height: 36px;
    line-height: 36px;
    border-bottom: 1px solid #dcdfe6;
    border-top: 1px solid #dcdfe6;
    padding-left: 16px;
    font-size: 14px;
    font-weight: 600;
    color: #303133;
}

.member-cont {
    min-height: 50px;
    overflow: auto;
}

.own {
    width: 100%;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    font-weight: 600;
    color: #303133;
    padding-left: 16px;
    border-bottom: 1px solid #dcdfe6;
}

.f-gui {
    width: 100%;
    height: 74px;
    padding-left: 16px;
    border-bottom: 1px solid #ebeef5;
}

.if-title {
    font-size: 14px;
    font-weight: 400;
    color: #606266;
    line-height: 46px;
}

.quali-table {
    width: 100%;
    border: 1px solid #dcdfe6;
    border-top: none;
    margin-bottom: 24px;
}

.education-cont {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid #dcdfe6;
}

.education {
    border: 1px solid #dcdfe6;
    border-top: none;
    border-bottom: none;
    margin-bottom: 24px;
}

.half-cont {
    width: 50%;
}

.work-info {
    border: 1px solid #dcdfe6;
    border-top: none;
    border-bottom: none;
    margin-bottom: 24px;
}

.family-member {
    margin-bottom: 24px;
    border: 1px solid #dcdfe6;
}

.company-finance {
    padding: 0 16px;
    margin: 14px 0;

    div {
        display: flex;
        align-items: center;
        color: #303133;
        font-size: 14px;
        margin-bottom: 8px;
        &::before {
            content: "*";
            color: #f56c6c;
            margin-right: 8px;
        }
        span {
            color: #409eff;
            margin: 0 8px;
        }
    }
}

.company-person {
    padding: 0 16px;
    margin: 14px 0;
    div {
        color: #303133;
        margin-bottom: 8px;
        font-size: 14px;
    }
    span {
        color: #409eff;
        margin: 0 8px;
    }
}

.company-noperson {
    padding: 0 16px;
    margin: 14px 0;
    > div {
        color: #303133;
        margin-bottom: 8px;
        font-size: 14px;
        margin-bottom: 10px;
    }
    .ans {
        padding-left: 17px;
        color: #606266;
        margin-bottom: 20px;
        .vi-item {
            margin: 20px 0;
        }
        span {
            font-size: 14px;
            margin-right: 10px;
        }
    }

    .person-table {
        padding-left: 17px;
        margin: 15px 0;
        width: 100%;
        background: #f5f7fa;
        box-sizing: border-box;
        display: flex;
        align-items: flex-start;
    }
}

.other-welfare {
    padding: 0 16px;
    margin: 14px 0;
    width: 100%;
    font-size: 14px;
    .tit {
        border-top: 1px solid #ebeef5;
        padding-top: 20px;
        font-size: 14px;
    }
    .bg-gray {
        padding: 20px;
        box-sizing: border-box;
        background: #f5f7fa;
        margin: 10px 0 20px;
        font-size: 14px;
        color: #303133;
        line-height: 25px;
    }
    .price {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 20px;
        div {
            width: 50%;
            span {
                font-size: 14px;
            }
            .num {
                color: #606266;
            }
        }
    }
}
.fontwei {
    font-weight: bold;
}
.file-item {
    margin-bottom: 10px;
}

.flie-url {
    cursor: pointer;
    color: #409eff;
    margin-left: 15px;
}
div.all-english-type:not(:last-of-type) {
    border-bottom: 1px solid #DCDFE6;
}
</style>
