<!--
 * @Author: vinota
 * @Description: 基础信息页面
-->
<template>
    <div>
        <div class="blue-cont">
            <div class="top-title">
                <div class="name">基础信息</div>
            </div>
        </div>
        <!-- 新基础信息 - 嵌入 -->
        <div class="info-frame">
            <iframe v-if="$store.state.type==='优才'" :src="`${url}/MiddleInfo/Gifted/${$store.state.user.orderId}?token=${$store.state.token}`"
                    name="clientFrame" frameborder="0"
                    width="100%" :height="clientFrameHeight"
            />
            <iframe v-if="$store.state.type==='澳门优才'" :src="`${url}/MiddleInfo/MacaoGifted/${$store.state.user.orderId}?token=${$store.state.token}`"
                    name="clientFrame" frameborder="0"
                    width="100%" :height="clientFrameHeight"
            />
            <iframe v-if="$store.state.type==='随迁'" :src="`${url}/MiddleInfo/Transfer/${$store.state.user.orderId}?token=${$store.state.token}`"
                    name="clientFrame" frameborder="0"
                    width="100%" :height="clientFrameHeight"
            />
        </div>
        <!-- 旧基础信息 -->
        <div v-if="$store.state.type!=='优才' && $store.state.type!=='澳门优才' && $store.state.type!=='随迁'" class="text-vbox">
            <el-tabs v-model="activeName">
                <el-tab-pane label="个人信息表" name="1">
                    <!-- 个人信息 -->
                    <PersonalInfo v-if="$store.state.type==='优才'" />
                    <ExpertPersonalInfo v-if="$store.state.type==='专才'" />
                    <StudyPersonalInfo v-if="$store.state.type==='留学'" />
                    <template v-if="$store.state.type === '小项目-优才'">
                        <!--[29, 55, 30, 32, 96] // 这五类产品走新逻辑，其他继续走以前逻辑 -->
                        <NewSmallGifted v-if="product && [29, 55, 30, 32, 96].includes(product.id)" />
                        <SmallGiftedInfo v-else />
                    </template>
                </el-tab-pane>
                <el-tab-pane v-if="$store.state.type==='优才'" label="我的评分" name="2">
                    <!-- 评分列表 -->
                    <ScoreTable :table-data="tableData" />
                </el-tab-pane>

            </el-tabs>
        </div>
        <show-code v-if="$store.state.type==='优才'" @suc="codeDialogSuc" />
    </div>
</template>

<script lang="javascript">
import { assessment } from "@/api/workfow.js"
import ScoreTable from "./Gifted/ScoreTable.vue" //优才评分
import PersonalInfo from "./Gifted/PersonalInfo.vue" //优才个人信息表
import ExpertPersonalInfo from "./Expert/PersonalInfo.vue" //专才个人信息表
import StudyPersonalInfo from "./Study/PersonalInfo.vue" //留学个人信息表
import SmallGiftedInfo from "./SmallGifted/PersonalInfo.vue" //优才小项目个人信息表
import NewSmallGifted from "./NewSmallGifted/index.vue" // 小项目优才5类产品新逻辑个人信息
import ShowCode from "@/components/ShowCode/ShowCode.vue"
export default {
    name: "CommonInfo",
    components: {
        ScoreTable,
        PersonalInfo,
        ExpertPersonalInfo,
        StudyPersonalInfo,
        SmallGiftedInfo,
        NewSmallGifted,
        ShowCode
    },
    data() {
        return {
            activeName: "1",
            tableData: {},
            clientFrameHeight: "",
            url: process.env.VUE_APP_USERINFO_URL,
            product: null
        };
    },
    created() {
        this.$store.commit("user");
        this.$store.commit("setType");
        this.product = JSON.parse(localStorage.getItem("product")) || null
        if (this.$store.state.type === "优才" || this.$store.state.type === "澳门优才") {
            // 优才
            assessment({ orderId: this.$store.state.user.orderId }).then(
                (res) => {
                    if (res.code == 200) {
                        this.tableData = res.data;
                    }
                }
            );
        }
    },
    mounted() {
        // 计算iframe应有的高度
        let elMainHeight = document.querySelector('.el-main').offsetHeight
        this.clientFrameHeight = (elMainHeight - 133) + 'px'
    },
    methods: {
        codeDialogSuc() {}
    },
};
</script>

<style lang="less" scoped>
.blue-cont {
    width: 100%;
    height: 130px;
    background: linear-gradient(313deg, #74cdff 0%, #409eff 100%);
}

.top-title {
    width: 960px;
    height: 130px;
    margin: 0 auto;
}

.name {
    font-size: 24px;
    font-weight: 500;
    color: #ffffff;
    padding-top: 46px;
}

.text-vbox {
    width: 962px;
    margin: 0 auto;
    padding-top: 40px;
}

.info-frame {
    width: 970px;
    margin: 0 auto;
}
</style>
