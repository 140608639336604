<!--
 * @Author: Vinota
 * @Description: 标题栏旁边的提示信息
-->
<template>
    <div class="tip">
        <el-tooltip class="item" effect="dark" content="Top Center 提示文字" placement="top">
            <div slot="content">
                <slot name="tipWord" />
            </div>
            <img class="img-width" src="~@/assets/img/tip_icon.png" />
        </el-tooltip>
    </div>
</template>

<script>
export default {
    name: "Tip",
    components: {},
    props: {
        width: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {};
    },
    methods: {

    },
};
</script>

<style lang="less" scoped>
.tip {
    width: 16px;
    height: 16px;
    position: relative;
    z-index: 99;
    margin-right: 5px;
}

.img-width {
    width: 16px;
    height: 16px;
    cursor: pointer;
}
</style>
