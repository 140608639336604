<!--
 * @Author: vinota
 * @Description: 我的评分(优才)
-->
<template>
    <div class="table-cont">
        <table width="962" border="1" style="border-collapse: collapse;" bordercolor="#DCDFE6">
            <thead>
                <tr>
                    <th width="68" class="text-css pad-left">编号</th>
                    <th width="732" class="text-css pad-left">内容</th>
                    <th width="104" class="text-css pad-left">客户信息</th>
                    <th width="104" class="text-css pad-left">客户得分</th>
                </tr>
            </thead>
            <tbody>
                <!-- 第一行 -->
                <tr>
                    <td width="56" class="text-css center-text">1</td>
                    <td width="732" class="text-css">
                        <div class="one-vi-text" style="height:31px;">年龄得分(最高30分)</div>
                        <div class="one-vi-text">18-39(30分)</div>
                        <div class="one-vi-text">40-44(20分)</div>
                        <div class="one-vi-text">45-50(15分)</div>
                        <div class="one-vi-text border-none">51+(0分)</div>
                    </td>
                    <td width="87" class="text-css">
                        <div class="vi-box">
                            <div class="vi-title" />
                            <div class="vi-flow">
                                年龄: {{ tableData.age }}
                            </div>
                        </div>
                    </td>
                    <td width="87" class="text-css">
                        <div class="vi-box">
                            <div class="vi-title" />
                            <div class="vi-flow">
                                {{ tableData.ageScore }}
                            </div>
                        </div>
                    </td>
                </tr>
                <!-- 第二行 -->
                <tr>
                    <td width="56" class="text-css center-text">
                        <div class="num-box center-text">
                            2
                        </div>
                        <div class="a-box center-text">
                            a
                        </div>
                        <div class="b-box center-text" style="line-height:40px;">
                            b
                        </div>
                    </td>
                    <td width="732" class="text-css">
                        <div class="one-vi-text" style="height:32px;line-height:25px;">学历/专业资格(最高70分)</div>
                        <div class="one-vi-text" style="height:34px;">(1)博士学位/2个或以上硕士学位(40分)</div>
                        <div class="one-vi-text">(2)硕士学位/2个或以上学士学位(20分)</div>
                        <div class="one-vi-text" style="height:33px;">(3)学士学位/由国家或国际认可或著名的专业团队颁授，证明持有人具有极高水平的专业知识或专业技能的专业资格(10分)</div>
                        <div class="one-vi-text border-none" style="height:32px;line-height:40px;">(4)如学士或以上程度的学位是由国际认可的著名院校颁授，可额外获取分数(30分)</div>
                    </td>
                    <td width="87" class="text-css">
                        <div class="num-box center-text" />
                        <div class="a-box center-text">
                            {{ tableData.educationA }}
                        </div>
                        <div class="b-box center-text" style="line-height:40px;">
                            {{ tableData.educationB }}
                        </div>
                    </td>
                    <td width="87" class="text-css">
                        <div class="num-box center-text" />
                        <div class="a-box center-text">
                            {{ tableData.educationScoreA }}
                        </div>
                        <div class="b-box center-text" style="line-height:40px;">
                            {{ tableData.educationScoreB }}
                        </div>
                    </td>
                </tr>

                <!-- 第三行 -->
                <tr>
                    <td width="56" class="text-css center-text">
                        <div class="find-box">
                            <div class="num-box center-text" style="height: 16px;line-height: 0px;">
                                3
                            </div>
                            <div class="a-box center-text" style="height: 166px;line-height: 166px;">
                                a
                            </div>
                            <div class="b-box center-text">
                                b
                            </div>
                        </div>
                    </td>
                    <td width="732" class="text-css">
                        <div class="one-vi-text" style="height:34px;line-height:30px;">工作经验(最高55分)</div>
                        <!-- <div class="one-vi-text">(1)不少于10年相当于学位程度或专家水平的工作经验，当中最少5年担任高级职位(40分)</div>
                        <div class="one-vi-text">(2)不少于5年相当于学位程度或专家水平的工作经验，当中最少2年担任高级职位(30分)</div>
                        <div class="one-vi-text" style="height:34px;">(3)不少于5年相当于学位程度或专家水平的工作经验(15分)</div>
                        <div class="one-vi-text">(4)不少于2年相当于学位程度或专家水平的工作经验(5分)</div>
                        <div class="one-vi-text">(5)获得最低学位证以后，拥有不超过2年工作经验(0分)</div> -->
                        <div class="one-vi-text">(1)获得最低学位证以后，拥有不超过2年工作经验(0分)</div>
                        <div class="one-vi-text">(2)不少于2年相当于学位程度或专家水平的工作经验(5分)</div>
                        <div class="one-vi-text" style="height:34px;">(3)不少于5年相当于学位程度或专家水平的工作经验(15分)</div>
                        <div class="one-vi-text">(4)不少于5年相当于学位程度或专家水平的工作经验，当中最少2年担任高级职位(30分)</div>
                        <div class="one-vi-text">(5)不少于10年相当于学位程度或专家水平的工作经验，当中最少5年担任高级职位(40分)</div>

                        <div class="one-vi-text border-none">如拥有不少于2年相当于学位程度或专家水平及在你原居国家/地区以外地方的国际工作经验，可额外获取分数(15分)</div>
                    </td>
                    <td width="87" class="text-css">
                        <div class="work-vcont">
                            <div class="num-box center-text" style="height:17px;" />
                            <div class="a-box center-text" style="height:166px;line-height:166px;">
                                {{ tableData.workExperienceA }}
                            </div>
                            <div class="b-box center-text">
                                {{ tableData.workExperienceB }}
                            </div>
                        </div>
                    </td>
                    <td width="87" class="text-css">
                        <div class="work-vcont">
                            <div class="num-box center-text" style="height:17px;" />
                            <div class="a-box center-text" style="height:166px;line-height:166px;">
                                {{ tableData.workExperienceScoreA }}
                            </div>
                            <div class="b-box center-text">
                                {{ tableData.workExperienceScoreB }}
                            </div>
                        </div>
                    </td>
                </tr>
                <!-- 第四行 -->
                <tr>
                    <td width="56" class="text-css center-text">
                        <div class="peo-cont">
                            <div class="per-text">4</div>
                            <div class="vi-num" />
                        </div>
                    </td>
                    <td width="732" class="text-css">
                        <div class="one-vi-text">人才清单(最高30分)</div>
                        <div class="one-vi-text">如符合人才清单内相关专业的规格，可额外获取分数</div>
                        <div class="one-vi-text border-none">请注明在人才清单内所属专业的名称:{{ tableData.position }}</div>
                    </td>
                    <td width="87" class="text-css">
                        <div class="peo-cont">
                            <div class="peo-title" />
                            <div class="peo-flow">
                                {{ tableData.talentList }}
                            </div>
                        </div>
                    </td>
                    <td width="87" class="text-css">
                        <div class="peo-cont">
                            <div class="peo-title" />
                            <div class="peo-flow">
                                {{ tableData.talentListScore }}
                            </div>
                        </div>
                    </td>
                </tr>
                <!-- 第五行 -->
                <tr>
                    <td width="56" class="text-css center-text">
                        <div class="lang-cont">
                            <div class="lang-text">5</div>
                            <div class="lang-num" />
                        </div>
                    </td>
                    <td width="732" class="text-css">
                        <div class="one-vi-text" style="height: 32px;">语言能力(最高20分)</div>
                        <div class="one-vi-text">(1)中文及英文 —— 国外大学(英语国家)毕业，英文授课，获得学位证。(如为中外联合办学，在中国上课的，不算)(20分)</div>
                        <div class="one-vi-text">(2)中文及英文 —— 有近两年的雅思或托福成绩单(雅思考试G类或A类总分达6分；托福笔试达550分，计算机达213分，网考达80分)(20分)</div>
                        <div class="one-vi-text">(3)中文及另一种外语 —— 非英文的外语，如：法文、日文等(需提供相关证书)(15分)</div>
                        <div class="one-vi-text border-none">(4)仅中文(10分)</div>
                    </td>
                    <td width="87" class="text-css">
                        <div class="lang-cont">
                            <div class="lang-title" />
                            <div class="lang-flow">
                                {{ tableData.language }}
                            </div>
                        </div>
                    </td>
                    <td width="87" class="text-css">
                        <div class="lang-cont">
                            <div class="lang-title" />
                            <div class="lang-flow">
                                {{ tableData.languageScore }}
                            </div>
                        </div>
                    </td>
                </tr>
                <!-- 第六行 -->
                <tr>
                    <td width="56" class="text-css center-text">

                        <div class="bg-cont">
                            <div class="wi-text">6</div>
                            <div class="lang-num" />
                        </div>
                    </td>
                    <td width="732" class="text-css">
                        <div class="one-vi-text" style="height: 32px;">家庭背景(最高20分)</div>
                        <div class="one-vi-text">(1)至少一名直系家庭（已婚配偶、父母、兄弟姊妹、子女）是现居于香港的香港永久性居民(5分)(20分)</div>
                        <div class="one-vi-text">(2)随行已婚配偶的学历相当于大学学位或以上的水平(5分)</div>
                        <div class="one-vi-text border-none">(3)每名随行的18岁以下未婚及收养的子女得5分，最高得10分(10分)</div>
                    </td>
                    <td width="87" class="text-css">
                        <div class="one-bg-text bg-width" />
                        <div class="one-bg-text">{{ tableData.backgroundA }}</div>
                        <div class="one-bg-text">{{ tableData.backgroundB }}</div>
                        <div class="one-bg-text border-none">{{ tableData.backgroundC }}</div>
                    </td>
                    <td width="87" class="text-css">
                        <div class="one-bg-text bg-width" />
                        <div class="one-bg-text">{{ tableData.backgroundScoreA }}</div>
                        <div class="one-bg-text">{{ tableData.backgroundScoreB }}</div>
                        <div class="one-bg-text border-none">{{ tableData.backgroundScoreC }}</div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td width="68" class="text-css pad-left" />
                    <td width="732" class="text-css pad-left">综合得分</td>
                    <td width="104" class="text-css pad-left" />
                    <td width="104" class="score">{{ tableData.sumScore }}</td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script lang="javascript">
export default {
    name: "ScoreTable",
    props: {
        tableData: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            activeName: "1",
        };
    },
    created() {},
    methods: {},
};
</script>

<style lang="less" scoped>
.pad-left {
    padding-left: 16px;
}

.border-none {
    border-bottom: none !important;
}

.text-css {
    font-size: 14px;

    font-weight: 400;
    color: #303133;
    text-align: left;
    line-height: 32px;
}

.center-text {
    text-align: center !important;
}

.one-vi-text {
    width: 100%;
    border-bottom: 1px solid #dcdfe6;
    padding-left: 16px;
}

.vi-box {
    width: 100%;
    height: 164px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.vi-title {
    width: 100%;
    height: 32px;
    border-bottom: 1px solid #dcdfe6;
    padding-left: 16px;
}

.vi-flow {
    width: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.num-box {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #dcdfe6;
}

.a-box {
    width: 100%;
    height: 100px;
    border-bottom: 1px solid #dcdfe6;
    line-height: 100px;
}

.b-box {
    width: 100%;
    height: 40px;
}

.find-box {
    width: 100%;
    height: 197px;
}

.work-vcont {
    width: 100%;
    height: 198px;
}

.peo-cont {
    height: 100px;
    width: 100%;
}

.per-text {
    width: 100%;
    height: 34px;
    border-bottom: 1px solid #dcdfe6;
}

.vi-num {
    width: 100%;
    height: 80px;
}

.peo-title {
    width: 100%;
    height: 34px;
    border-bottom: 1px solid #dcdfe6;
    padding-left: 16px;
}

.peo-flow {
    width: 100%;
    height: 68px;
    text-align: center;
    line-height: 68px;
}

.lang-cont {
    width: 100%;
    height: 196px;
}

.bg-cont {
    width: 100%;
    height: 132px;
}

.lang-text {
    border-bottom: 1px solid #dcdfe6;
}

.wi-text {
    border-bottom: 1px solid #dcdfe6;
}

.lang-title {
    width: 100%;
    height: 33px;
    border-bottom: 1px solid #dcdfe6;
}

.lang-flow {
    width: 100%;
    height: 164px;
    text-align: center;
    line-height: 164px;
}

.pad-none {
    padding-left: none !important;
}

.one-bg-text {
    width: 100%;
    border-bottom: 1px solid #dcdfe6;
    text-align: center;
}

.score {
    text-align: center;
}

.table-cont {
    margin-bottom: 54px;
}

.bg-width {
    width: 100%;
    height: 32px;
}
</style>
